import React, { useState, useEffect } from 'react';
import {
  Route as ReactDOMRoute,
  Redirect,
  RouteProps as ReactDOMRouteProps,
  useParams,
  useRouteMatch
} from 'react-router-dom';
import { useAuth } from '../hooks/auth';

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  component: React.ComponentType;
}



const Route: React.FC<RouteProps> = ({ isPrivate = false, component: Component, ...rest }) => {
  const { user} = useAuth();



  // useEffect(() => {
  //   console.log(match)
  //   const parsedData = window.location.pathname.split("/");
  //   console.log('tenant do route2: '+parsedData);
  //   let domain = parsedData[1];
  //   let idBranch = parsedData[2];
  //   setDomain(domain);
  //   setIdBranch(idBranch);
  //   console.log(domain);
  // },[window])

  return (
    <ReactDOMRoute  {...rest}
      render={({ location  }) => {


        // return isPrivate === !!user ? (
        //   <Component />
        //   ): (
        //       <Redirect
        //       to={{pathname: isPrivate ? '/': '/dashboard',
        //       state: {from: location},
        //       }}
        //     />
        //   )

        return ((isPrivate === !!user) || (!isPrivate && !!user)) ?

          (location.pathname == "/" && !!user) ? (
            <Redirect
              to={{
                pathname: "/dashboard",
                state: { from: location },
              }}
            />) : (
               <Component />
            ) : (
            <Redirect
              to={{
                pathname: isPrivate ? '/' : '/dashboard',
                state: { from: location },
              }}
            />
          )
      }
      } />
  )
};
export default Route;

