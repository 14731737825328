import React, {  useEffect, useMemo, useState, useCallback } from 'react'
import differenceInSeconds from "date-fns/differenceInSeconds";
import { TypographyProps, Typography, createStyles, Theme, makeStyles } from '@material-ui/core';
import { datePickerDefaultProps } from '@material-ui/pickers/constants/prop-types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    start: {
      color: theme.palette.error.main,
      marginLeft: '10px',
      fontSize: '14px',
    },
  })
);
const NOOP = () => {};
type ITimeProps = TypographyProps & {
  start: Date;
}


const Timer: React.FC<ITimeProps>  = ({start, ...props}) => {



  const ONE_DAY = 60 * 60 * 24;
  const ONE_HOUR = 60 * 60;
  const ONE_MINUTE = 60;
  const [currentTime, setCurrentTime] = useState(new Date().getTime());
  const diffInSeconds = differenceInSeconds(start, currentTime);


  const getCoundown = () => {
    if (diffInSeconds <= 1) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      };
    }
    const days = Math.floor(diffInSeconds / ONE_DAY);
    const hours = Math.floor((diffInSeconds - days * ONE_DAY) / ONE_HOUR);
    const minutes = Math.floor(
      (diffInSeconds - days * ONE_DAY - hours * ONE_HOUR) / ONE_MINUTE
    );
    const seconds = diffInSeconds - days * ONE_DAY - hours * ONE_HOUR - minutes * ONE_MINUTE;



    return {
      days,
      hours,
      minutes,
      seconds
    };
  };

  const countdown = useMemo(getCoundown, [currentTime]);



  const classes = useStyles();
  useEffect(() => {
    setInterval(() => {
      const now = new Date().getTime();
      setCurrentTime(now);
    }, 1000);
  }, []);


        return (
            <>
                { countdown.hours === 0 && countdown.minutes === 0 && countdown.seconds === 0
                    ?
                    //Tempo esgotado
                    <Typography   {...props} className={classes.start}>Começou</Typography>
                    :
                    //Tempo ainda não esgotado
                    <Typography  {...props}>Começa em
                      {countdown.hours > 0? (countdown.hours == 1?` ${countdown.hours} hora`+ (countdown.minutes> 5?` e ${countdown.minutes} minutos`:'')  :` ${countdown.hours} horas.`):
                      countdown.minutes > 0? (countdown.minutes == 1?` ${countdown.minutes} minuto.`:` ${countdown.minutes} minutos.`):
                      " menos de 1 minuto."}</Typography>
                }
            </>
        )

}
export default Timer;
