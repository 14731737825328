import React, { useCallback } from 'react';
import classNames from "classnames";
import { useAuth } from '../../../hooks/auth';
import { useHistory } from 'react-router-dom';
import { ButtonsList } from './styles';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { AppBar, Toolbar, IconButton, Typography, Button } from "@material-ui/core";
import { Assignment, PersonPinCircle, MonetizationOn, AccountBox, PowerSettingsNew, Event } from '@material-ui/icons';
import GridContainer from '../../../components/Grid/GridContainer'
import GridItem from '../../../components/Grid/GridItem'
import MenuIcon from "@material-ui/icons/Menu";
import { Content } from "./styles";
import profile from "../../assets/img/christian.jpg";
import Header from '../../../components/Header';



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },

  },


  ),
);

const PaymentChoice: React.FC = () => {
  const classes = useStyles();

  const history = useHistory();
  const { signOut } = useAuth();

  const handleLogout = useCallback(async () => {
    await signOut();
    history.push('/');
  }, [history, signOut]);

  return (
    <>
     <Header isBack avatar />

      <div>
        <div>
          <Content>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={10}>
                <div >
                  <ButtonsList>
                    <Button variant="contained" size="large" color="primary" >
                      <AccountBox />
                  Cartão de crédito
                  </Button>

                  </ButtonsList>
                </div>
              </GridItem>
            </GridContainer>
          </Content>
        </div>
      </div>
    </>
  )

}



export default PaymentChoice;
