import axios from 'axios';

// const apiToken = axios.create({
//     baseURL: "http://localhost:53114/",
// });
const apiToken = axios.create({
  headers: { "Content-Type": "application/x-www-form-urlencoded" },
  baseURL: "https://apihubfitapp.azurewebsites.net/",
});

export default apiToken;
