import styled, {css} from 'styled-components';

import { palette } from '@material-ui/system';



export const CardDiv = styled.div`

    border: 0;
    margin-bottom: 30px;
    margin-top: 30px;
    border-radius: 6px;
    color: rgba(0, 0, 0, 0.87);
    background: #fff;
    width: 100%;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    font-size: .875rem;
    transition: all 300ms linear;


`;
export const CardDivBody = styled.div`
    padding: 0.9375rem 1.075rem;
    flex: 1 1 auto;
`;
export const CardFooterDivBody = styled.div`
        display: flex;
    align-items: center;
    background-color: transparent;
    padding: 0.9375rem 1.875rem;

`;
export const CardHeaderDivBody = styled.div`

  >h4{
      /* margin-top: 14px !important; */
      color: #fff !important;
      flex: 1;
      display: flex;
      align-items: center;
    }


    border-radius: 3px;
    padding: 1rem 15px;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: -40px;
    border: 0;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    height: 100px;
    flex-direction: column;
    text-align: center;
    img{

      width: 120px;
    }

`;
