import React, { useState, useCallback } from "react";
import QrReader from 'react-qr-reader'
// nodejs library to set properties for components
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Dialog, DialogTitle, DialogContent, Grid, Button, createStyles, Theme, DialogProps, Backdrop, CircularProgress } from "@material-ui/core";
import { useToast } from "../../hooks/toast";
import { useAuth } from '../../hooks/auth';
import { Guid } from "guid-typescript";
import { HelpOutline } from "@material-ui/icons";
import { blue } from "@material-ui/core/colors";
import { IClassItem } from "../../models/Classes/IClassesDTO";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      cursor: 'pointer',
    },
    message: {
      margin: '15px 0',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    searching: {
      display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    },
      IconInfo: {
        color: blue[500],
        marginRight: '7px'
      },
      divInfo:{
        display: 'flex',
    margin: '10px 0',
      }
  })
);

type QrCodeProps = DialogProps &{
  aula?: IClassItem;
}

const DialogQrCode: React.FC<QrCodeProps> = ({aula , ...rest }) => {

 

  const classes = useStyles();
  const [result, setResult] = useState('sem resultado');
  const [reading, setReading] = useState(false);
  const [habilitado, setHabilitado] = useState(false);
  const { addToast } = useToast();
  const { signInQrCode } = useAuth();
  const history = useHistory();


  const handleScan = useCallback(async (data) => {

    try {
      if (data && !reading) {
        console.log(data);
        if (Guid.isGuid(Guid.parse(data))) {
          setReading(true);
          await signInQrCode(data);
          if (aula) {
            sessionStorage.setItem('@HubfitTotem:class', JSON.stringify(aula));
            sessionStorage.setItem('@HubfitTotem:action', "class_register");
            history.push('/classes/list')
          } else {
            history.push('/dashboard')
          }
          setReading(false);
        }
      }
    } catch (err) {
      console.log(err)
      setReading(false);
    }
    //
  }, [reading,history,aula])
  const handleError = useCallback((err) => {
      if(err == "NotFoundError: Requested device not found"){
        addToast({
          type: "error",
          title: 'Erro',
          description: "Câmera não encontrada.",
        });
      }

   
  }, [addToast])
  const handleLoad = useCallback(() => {
    setHabilitado(true);
  }, [])

  return (
    <Dialog maxWidth="sm" fullWidth aria-describedby="form-dialog-title"  {...rest}>

      <DialogTitle id="confirmation-dialog-title">Leitura de QRCode</DialogTitle>

      <DialogContent dividers>
  {habilitado &&
  <div className={classes.divInfo}>
      <HelpOutline fontSize="small" className={classes.IconInfo} />
      Acesse o aplicativo no celular, clique na opção de QRCode e aponte o código para a câmera para fazer login.
      </div>
  }
        <div >
          <Grid direction="column"
            justify="center"
            alignItems="center" >

            <Grid item xs={12}   >
              <QrReader
                delay={300}
                onError={handleError}
                onScan={handleScan}
                onLoad={handleLoad}
                style={{ width: '100%' }}
                facingMode="user"
              />
              {reading &&
               <Backdrop className={classes.backdrop} open={reading}>
                 <div className={classes.searching}>
               <CircularProgress color="inherit" />
               <p>Pesquisando</p>
               </div>
             </Backdrop>
}
              {!habilitado &&
                <p className={classes.message}>Você deve clicar em <b>permitir</b> para iniciar a leitura.</p>
              }

            </Grid>
          </Grid>
        </div>
      </DialogContent>
    </Dialog>
  );
}



export default DialogQrCode;
