import React from "react";
import{CardDivBody} from './styles';



const CardBody: React.FC = ({children,  ...rest }) => {

  return (
    <CardDivBody {...rest}>
      {children}
    </CardDivBody>
  );
}

export default CardBody;
