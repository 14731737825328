import React, { useCallback, useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../../hooks/auth';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import InputMask from "react-input-mask";
import * as Yup from "yup";
import { ButtonsList, GridFooterCard, ContentModal, Content } from './styles';
import { createStyles, makeStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import {
  AppBar, Toolbar, IconButton, Typography, Button, Grid, Divider,
  DialogTitle as MuiDialogTitle, DialogContent as MuiDialogContent, DialogActions as MuiDialogActions, Dialog, InputAdornment, CircularProgress, Input, FormControl, InputLabel, FormHelperText, Grow, Paper, useScrollTrigger, Fab, Zoom
} from "@material-ui/core";
import { Skeleton } from '@material-ui/lab';
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import { Assignment, PersonPinCircle, MonetizationOn, AccountBox, PowerSettingsNew, Event, Close as CloseIcon, Lock, AccountCircle, HelpOutline, ArrowForwardIos, PersonAdd, ArrowLeft, KeyboardArrowUp } from '@material-ui/icons';
import MenuIcon from "@material-ui/icons/Menu";

import profile from "../../assets/img/christian.jpg";
import api from "../../../services/api";
import CardHeader from "../../../components/Card/CardHeader";
import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";
import CardFooter from "../../../components/Card/CardFooter";
import NumberFormat from "react-number-format";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import SignInModal from "../../Login/SignInModal";
import { CardTextHeader } from "../../Login/SignIn/styles";
import { green, blue } from "@material-ui/core/colors";
import getValidationErrors from "../../../utils/getValidationErrors";
import { useToast } from "../../../hooks/toast";
import Header from "../../../components/Header";
import { IPlanItem } from "../../../models/Plans/IPlanDTO";
import DialogPlanDetails from "../../../components/DialogPlanDetails/Index";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    valuePlan: {
      marginTop: "2px !important",
      marginBottom: "2px !important",
    },
    carrouselLogin: {
      display: "none",
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    buttonProgress: {
      color: green[500],
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    wrapper: {
      margin: "60px 0 0",
      width: "100%",
      position: "relative",
    },

    SpanCard: {
      color: "rgba(0,0,0,0.5)",
      display: "flex",
      alignItems: "center",
    },
    IconInfo: {
      color: blue[500],
    },
    RingOff: {
      "outline-style": "inherit",
    },
    carrouselInvisible: {
      display: "none",
    },
    noPlans: {
      padding: "60px",
      opacity: "0.7",
      "& h5": {
        color: theme.palette.text.secondary,
      },
    },
    btnItemLogin: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    },
    btnItemLoginComponent: {
      marginBottom: "50px",
    },
    textItemLogin: {
      margin: "26px 0",
    },
    textCenter: {
      textAlign: "center",
    },
    backToTopAnchor: {
      height: '0px',
      minHeight: '0px',
    },
    noClasses: {
      textAlign: "center",
      padding: "60px",
      opacity: "0.7",
      "& h5": {
        color: theme.palette.text.secondary,
      },
    },
    overflowCard: {
      overflow: 'inherit !important',
    },
    textObs: {
      color: theme.palette.text.secondary,
      fontSize: '13px',
    },
  })
);

interface FindCPFFormData {
  cpf: string;
}

interface SignInFormData {
  password: string;
}
interface IUserLogin {
  avatarUrl: string;
  email: string;
  nome: string;
  id: number;
  tipoPessoa: number;
  cadastroProspect: boolean;
  cadastro: number;
}

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });



interface PropsZoom {
  window?: () => Window;
  children: React.ReactElement;
}

const useStylesZoom = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  }),
);

function ScrollTop(props: PropsZoom) {
  const { children, window } = props;
  const classes = useStylesZoom();
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <Zoom in={trigger} style={{ zIndex: 999 }}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}
const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
let dataFormsCpf: FindCPFFormData;

const List: React.FC = () => {
  const classes = useStyles();
  const { user, tenant, signIn } = useAuth();
  const [planSelected, setPlanSelected] = useState<IPlanItem>();
  const [openDetails, setOpenDetails] = React.useState(false);
  const [listPlans, setListPlans] = useState<IPlanItem[]>([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [openModalLogin, setOpenModalLogin] = React.useState(false);
  const [openModalSignUp, setOpenModalSignUp] = React.useState(false);
  const [startEffect, setStartEffect] = React.useState(false);
  const [tab, setTab] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [loadingPlans, setLoadingPlans] = React.useState(true);
  const [maxWidthDialog, setMaxWidthDialog] = React.useState<"sm" | "xs">("sm");
  const [userTemp, setUserTemp] = React.useState<IUserLogin>();
  const { addToast } = useToast();
  const history = useHistory();

  const cpfRef = useRef<HTMLInputElement | null>(null);
  const cpfSignupRef = useRef<HTMLInputElement | null>(null);
  const passwordRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setLoadingPlans(true);
    sessionStorage.removeItem("@HubfitTotem:plan");
    sessionStorage.removeItem("@HubfitTotem:action");
    api
      .get("/plans/totem", {
        params: {
          idTenant: tenant.idTenant,
          idTotem: tenant.idTotem,
        },
      })
      .then((response) => {
        if (tenant.permissions.renewSales == false) {
          const list = response.data as IPlanItem[];
          const newList = list.filter((item) => {
            return item.isRenovacao == false;
          });
          setListPlans(newList);
        } else {
          setListPlans(response.data);
        }
        setLoadingPlans(false);
        setStartEffect(true);
      });
  }, []);
  const handleClickReturn = useCallback(() => {
    history.push("/dashboard");
  }, [history]);
  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
    setTimeout(() => {
      setOpenModalSignUp(false);
      setOpenModalLogin(false);
      setMaxWidthDialog("sm");
      setTab(0);
    }, 800);
    sessionStorage.removeItem("@HubfitTotem:plan");
    sessionStorage.removeItem("@HubfitTotem:action");
  }, []);

  const handleClickBuy = useCallback(
    (id: string) => {
      const planSelected = listPlans?.find((e) => e.id == id);
      sessionStorage.setItem("@HubfitTotem:plan", JSON.stringify(planSelected));
      sessionStorage.setItem("@HubfitTotem:action", "plan_payment");
      if (user) {
        history.push("/payments/checkout");
      } else {
        setOpenModal(true);
      }
    },
    [user, setOpenModal, listPlans]
  );

  const handleClickSignIn = useCallback(() => {
    setMaxWidthDialog("xs");
    setOpenModalSignUp(false);
    setOpenModalLogin(true);

    setTimeout(() => {
      cpfRef.current?.focus();
    }, 800);
  }, [history]);

  const handleClickSignUp = useCallback(() => {
    // history.push('/signup');
    setMaxWidthDialog("xs");
    setOpenModalSignUp(true);
    setTimeout(() => {
      cpfSignupRef.current?.focus();
    }, 400);
  }, []);

  function validadeCPF<bollean>(cpf: string) {
    console.log(cpf)
    cpf = cpf.replace('.', '',).replace('.', '',).replace('-', '');
    console.log(cpf)
    if (cpf == null) {
      return false;
    }
    if (cpf.length != 11) {
      return false;
    }
    if ((cpf == '00000000000') || (cpf == '11111111111') || (cpf == '22222222222') || (cpf == '33333333333') || (cpf == '44444444444') || (cpf == '55555555555') || (cpf == '66666666666') || (cpf == '77777777777') || (cpf == '88888888888') || (cpf == '99999999999')) {
      return false;
    }
    let numero: number = 0;
    let caracter: string = '';
    let numeros: string = '0123456789';
    let j: number = 10;
    let somatorio: number = 0;
    let resto: number = 0;
    let digito1: number = 0;
    let digito2: number = 0;
    let cpfAux: string = '';
    cpfAux = cpf.substring(0, 9);
    for (let i: number = 0; i < 9; i++) {
      caracter = cpfAux.charAt(i);
      if (numeros.search(caracter) == -1) {
        return false;
      }
      numero = Number(caracter);
      somatorio = somatorio + (numero * j);
      j--;
    }
    resto = somatorio % 11;
    digito1 = 11 - resto;
    if (digito1 > 9) {
      digito1 = 0;
    }
    j = 11;
    somatorio = 0;
    cpfAux = cpfAux + digito1;
    for (let i: number = 0; i < 10; i++) {
      caracter = cpfAux.charAt(i);
      numero = Number(caracter);
      somatorio = somatorio + (numero * j);
      j--;
    }
    resto = somatorio % 11;
    digito2 = 11 - resto;
    if (digito2 > 9) {
      digito2 = 0;
    }
    cpfAux = cpfAux + digito2;
    if (cpf != cpfAux) {
      return false;
    }

    return true;


  }
  const RegisterSignInPasswordSchema = Yup.object().shape({
    password: Yup.string().required("Digite a senha"),
  });
  const RegisterSignInCPFSchema = Yup.object().shape({
    cpf: Yup.string()
      .test("cpf", "CPF incorreto", (value) => validadeCPF(value))
      .required("CPF obrigatório"),
  });
  const formCpf = useForm<FindCPFFormData>({
    resolver: yupResolver(RegisterSignInCPFSchema),
  });
  const formPassword = useForm<SignInFormData>({
    resolver: yupResolver(RegisterSignInPasswordSchema),
  });
  const formCpfSignUp = useForm<FindCPFFormData>({
    resolver: yupResolver(RegisterSignInCPFSchema),
  });
  const handleSubmitFindCPFSignUp = useCallback(
    async (dataForm: FindCPFFormData) => {
      try {
        setLoading(true);
        // Buscar por CPF na API
        const { data } = await api.get("/users/cpf", {
          params: {
            cpf: dataForm.cpf,
            idTenant: tenant.idTenant,
          },
        });
        switch (data.cadastro) {
          case 0:
            // Sem cadastro, direcionar para tela de cadastro.
            history.push({
              pathname: "/signup",
              state: {
                cpf: dataForm.cpf,
              },
            });
            break;
        }
        console.log(data);
      } catch (err) {
        console.log(err);
        return;
      } finally {
        setLoading(false);
      }
    },
    []
  );
  const handleSubmitFindCPF = useCallback(async (dataForm: FindCPFFormData) => {
    try {
      setLoading(true);
      // Buscar por CPF na API
      const { data } = await api.get("/users/cpf", {
        params: {
          cpf: dataForm.cpf,
          idTenant: tenant.idTenant,
        },
      });

      if (data.nome) {
        setUserTemp(data);
        setTab(1);
        setTimeout(() => {
          passwordRef.current?.focus();
        }, 800);
      }
    } catch (err) {
      console.log(err);

      return;
    } finally {
      setLoading(false);
    }
  }, []);
  const handleSubmitSigIn = useCallback(
    async (data: SignInFormData) => {
      try {
        console.log(userTemp);
        setLoading(true);

        if (!userTemp) {
          throw new Error("Cadastro não encontrado");
        }
        await signIn({
          email: userTemp.email,
          password: data.password,
        });

        history.push("/payments/checkout");
      } catch (err) {
        console.log(err);
        setLoading(false);

        addToast({
          type: "error",
          title: "Erro na autenticação",
          description: "Erro no login, verifique suas credenciais.",
        });
      } finally {
        setLoading(false);
      }
    },
    [history, signIn, addToast, userTemp]
  );

  const handleClickDetails = useCallback((plano) => {
    setPlanSelected(plano);
    setOpenDetails(true);
  }, [])
  const handleCloseModalDetails = useCallback(() => {
    setOpenDetails(false);
  }, []);




  return (
    <>
      <Header linkPush="/dashboard" avatar />
      <Toolbar id="back-to-top-anchor" className={classes.backToTopAnchor} />
      <div>
        <div>
          <Content>
            <Grid container spacing={3}>
              {loadingPlans &&
                <>
                  <Grid item xs={12} sm={6} md={4} lg={3}  >
                    <Card>
                      <CardBody>
                        <h2>
                          <Skeleton animation="wave" variant="rect" />
                        </h2>
                        <Skeleton animation="wave" variant="rect" />
                        <Skeleton animation="wave" variant="rect" />
                      </CardBody>
                      <Divider variant="fullWidth" />
                      <CardBody>
                        <h4>
                          <Skeleton animation="wave" variant="rect" />
                          <Skeleton animation="wave" variant="rect" />
                        </h4>
                      </CardBody>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}  >
                    <Card>
                      <CardBody>
                        <h2>
                          <Skeleton animation="wave" variant="rect" />
                        </h2>
                        <Skeleton animation="wave" variant="rect" />
                        <Skeleton animation="wave" variant="rect" />
                      </CardBody>
                      <Divider variant="fullWidth" />
                      <CardBody>
                        <h4>
                          <Skeleton animation="wave" variant="rect" />
                          <Skeleton animation="wave" variant="rect" />
                        </h4>
                      </CardBody>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4} lg={3}  >
                    <Card>
                      <CardBody>
                        <h2>
                          <Skeleton animation="wave" variant="rect" />
                        </h2>
                        <Skeleton animation="wave" variant="rect" />
                        <Skeleton animation="wave" variant="rect" />
                      </CardBody>
                      <Divider variant="fullWidth" />
                      <CardBody>
                        <h4>
                          <Skeleton animation="wave" variant="rect" />
                          <Skeleton animation="wave" variant="rect" />
                        </h4>
                      </CardBody>
                    </Card>
                  </Grid>
                </>
              }

              {(!listPlans || listPlans.length == 0) && !loadingPlans &&
                <Grid item xs={12}  >
                  <Paper elevation={3} className={classes.noClasses} >
                    <Typography align="center" variant="h5" component="h5" >Nenhum plano disponível</Typography>
                    <Button onClick={handleClickReturn}>
                      <ArrowLeft />
                      Voltar
                    </Button>
                  </Paper>
                </Grid>
              }
              {listPlans?.map((plan) => (
                <Grow
                  key={plan.id}
                  in={startEffect}
                  style={{ transformOrigin: "0 0 0" }}
                  {...(startEffect
                    ? { timeout: 500 * listPlans.indexOf(plan) }
                    : {})}
                >
                  <Grid item xs={12} sm={6} md={4} lg={3}>
                    <Card>
                      <CardHeader>
                        <Typography variant="h4">{plan.nomePlano}</Typography>
                      </CardHeader>

                      <CardBody>
                        {plan.parcelas > 1 && !plan.dcc && (
                          <>
                            <Typography align="center">
                              Em até
                              {' '}
                              {plan.parcelas}
                              x de
                            </Typography>
                            <Typography
                              variant="h3"
                              align="center"
                              className={classes.valuePlan}
                            >
                              <NumberFormat
                                value={(plan.valorTotal-plan.adesaoValor) / plan.parcelas}
                                displayType={"text"}
                                decimalSeparator=","
                                thousandSeparator="."
                                decimalScale={2}
                                fixedDecimalScale={true}
                                prefix={"R$"}
                              />
                            </Typography>
                          </>
                        )}
                        {plan.parcelas == 1 && !plan.dcc && (
                          <>
                            <Typography align="center">à vista</Typography>
                            <Typography
                              variant="h3"
                              align="center"
                              className={classes.valuePlan}
                            >
                              <NumberFormat
                                value={(plan.valorTotal-plan.adesaoValor) / plan.parcelas}
                                displayType={"text"}
                                decimalSeparator=","
                                thousandSeparator="."
                                decimalScale={2}
                                fixedDecimalScale={true}
                                prefix={"R$"}
                              />
                            </Typography>
                          </>
                        )}
                        {plan.dcc && (
                          <>
                            <Typography align="center">Mensalidade</Typography>
                            <Typography
                              variant="h3"
                              align="center"
                              className={classes.valuePlan}
                            >
                              <NumberFormat
                                value={plan.valorDcc}
                                displayType={"text"}
                                decimalSeparator=","
                                thousandSeparator="."
                                decimalScale={2}
                                fixedDecimalScale={true}
                                prefix={"R$"}
                              />
                            </Typography>
                          </>
                        )}
                      </CardBody>
                      <Divider variant="fullWidth" />
                      <CardBody>
                        <GridFooterCard>
                          <Grid

                            container
                            direction="row"
                            justify="space-between"
                            alignItems="center"
                          >
                            <Grid>
                              <p>Adesão:</p>
                            </Grid>
                            <Grid>
                              <NumberFormat
                                value={plan.adesaoValor}
                                displayType={"text"}
                                decimalSeparator=","
                                thousandSeparator="."
                                decimalScale={2}
                                fixedDecimalScale={true}
                                prefix={"R$"}
                              />
                            </Grid>
                          </Grid>
                        </GridFooterCard>
                      </CardBody>
                      <CardFooter>
                        <Grid

                          container
                          spacing={1}
                          direction="row"
                          justify="center"
                          alignItems="center"
                        >
                          <Grid item xs={12} alignContent="center">
                            <Button
                              fullWidth
                              color="secondary"
                              onClick={() => handleClickDetails(plan)}
                            >
                              Mais detalhes
                            </Button>
                          </Grid>
                          <Grid item xs={12}>
                            <Button fullWidth color={plan.isRenovacao ? "secondary" : "primary"} variant="contained" size="medium" onClick={() => handleClickBuy(plan.id)}>
                              {plan.isRenovacao &&
                                "Renovar"
                              }
                              {!plan.isRenovacao &&
                                "Comprar"
                              }
                            </Button>
                          </Grid>
                        </Grid>
                      </CardFooter>
                    </Card>
                  </Grid>
                </Grow>
              ))}
            </Grid>
          </Content>
        </div>
      </div>
      <ScrollTop >
        <Fab color="primary" size="small" aria-label="Voltar ao topo">
          <KeyboardArrowUp />
        </Fab>
      </ScrollTop>
      <Dialog scroll={"body"} PaperProps={{ className: classes.overflowCard }} maxWidth="sm" fullWidth onClose={handleCloseModal} aria-describedby="form-dialog-title" open={openModal}>

        <SignInModal />

      </Dialog>
      <DialogPlanDetails buyClick={(plano: IPlanItem) => handleClickBuy(plano.id)} onClose={handleCloseModalDetails} open={openDetails} plan={planSelected} />
    </>
  );
};

export default List;
