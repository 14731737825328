import styled, {keyframes} from 'styled-components';
import {shade} from 'polished';

export const Content = styled.div`
  width: 100%;

    position: relative;

`;

const appearFromLeft = keyframes`
    from{
        opacity: 0;
        transform: translateX(-50px);

    }
    to{
        opacity: 1;
        transform: translateX(0);
    }
`;



export const AnimationContainer = styled.div`

    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    animation: ${appearFromLeft} 1s;

form{
        margin: 80px 0;
        width:340px;
        text-align:center;
        h1{
            margin-bottom: 24px;
        }



        a{
            color: #f4ede8;
            display: block;
            margin-top: 24px;
            text-decoration: none;
            transition: color 0.2s;
            &:hover{
                color: ${shade(0.2,'#f4ede8')};
            }
        }
    }
    > a{
        color: #ff9000;
        display: block;
        margin-top: 24px;
        text-decoration: none;
        transition: color 0.2s;
        display: flex;
        align-items: center;
        svg{
            margin-right: 16px;
        }
        &:hover{
            color: ${shade(0.2,'#ff9000')};
        }
    }
`;

export const Background = styled.div`

    min-height: 100vh;
    height: auto;
    display: inherit;
    position: relative;
    margin: 0;
    padding: 0;
    border: 0;
    align-items: center;
    /* &:before {
      background: rgba(0, 0, 0, 0.7);
    };
    &:before, &:after {
      position: absolute;
      z-index: -1;
      width: 100%;
      height: 100%;
      display: block;
      left: 0;
      top: 0;
      content: '';
    }; */
    & footer li a,& footer li a:hover,& footer li a:active{
      color: #FFFFFF;
    };
    & footer{
      position: absolute;
      bottom: 0;
      width: 100%;
    };
`;
