import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {GridProps} from '@material-ui/core'

const styles = {
  grid: {
    marginRight: "-15px",
    marginLeft: "-15px",
    width: "auto"
  }
};

const useStyles = makeStyles(styles);

const GridContainer: React.FC<GridProps> = ({children, className, ...rest}) => {
  const classes = useStyles();

  return (
    <Grid container {...rest} className={classes.grid + " " + className}>
      {children}
    </Grid>
  );
}
// export default function GridContainer(props) {
//   const classes = useStyles();
//   const { children, className, ...rest } = props;
//   return (
//     <Grid container {...rest} className={classes.grid + " " + className}>
//       {children}
//     </Grid>
//   );
// }

GridContainer.defaultProps = {
  className: ""
};

GridContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};
export default GridContainer;
