import React, { useState, useCallback, useEffect } from "react";
import axios from 'axios';


// nodejs library to set properties for components

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Dialog, DialogTitle, DialogContent, Grid, createStyles, Theme, DialogProps, Backdrop, CircularProgress, IconButton,  } from "@material-ui/core";
import { useToast } from "../../hooks/toast";
import { useAuth } from '../../hooks/auth';
import { Guid } from "guid-typescript";
import { HelpOutline, Close } from "@material-ui/icons";
import { blue } from "@material-ui/core/colors";
import { IClassItem } from "../../models/Classes/IClassesDTO";
import { useHistory } from "react-router-dom";
import animationData from '../../assets/animations/fingerprint-scanner.json';
import animationDataSaved from '../../assets/animations/success.json';
import Lottie from "react-lottie";
import { hubConnection } from "signalr-no-jquery";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      cursor: 'pointer',
    },
    message: {
      margin: '15px 0',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    searching: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    IconInfo: {
      color: blue[500],
      marginRight: '7px'
    },
    divInfo: {
      display: 'flex',
      margin: '10px 0 40px',
      alignItems: 'center',
      flexDirection:'column',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })
);
type BiometricProps = DialogProps & {
 opened: boolean;
}

const DialogBiometricRegister: React.FC<BiometricProps> = ({opened, ...rest }) => {
  const classes = useStyles();
  const { onClose } = rest;
  const [connection, setConnection] = useState<SignalR.Hub.Connection>();
  const [result, setResult] = useState('Coloque o dedo no leitor biométrico');

  const [saved, setSaved] = useState(false);
  const { addToast } = useToast();
  const { tenant, user } = useAuth();
  const history = useHistory();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  };
  const savedOptions = {
    loop: false,
    autoplay: true,
    animationData: animationDataSaved,
  };

  useEffect(() => {
    setSaved(false);
    setResult('Coloque o dedo no leitor biométrico');
    if (tenant.localApiStatus == true && opened) {
      axios.get('http://'+tenant.localApiUrl+':5001/api/biometrics/registermode', {params:{
        id: user.id,
        identifier: user.identifier,
        type: user.type,
      }}).catch((err) => {
        addToast({
          type: "error",
          title: 'Erro',
          description: "Não foi possível ativar o leitor para iniciar o registro",
        });
      })

      let connection = hubConnection('http://'+tenant.localApiUrl+':5001');
      const myHubProxy = connection.createHubProxy('ServerHub');


      myHubProxy.on('StatusRegisterBiometry', function (message) {
        console.log('status: ', message)
        if (message) {
          console.log(message)
          try {
            if(message.success == true){
              setSaved(true);
              setResult(message.msg);
              axios.get('http://'+tenant.localApiUrl+':5001/api/biometrics/readermode').catch((err) => {
              })
            }else{
              setSaved(false);
              setResult(message.msg);
            }
          } catch (err) {

          }
        }
      });
      connection.start({ jsonp: true })
        .done(function () { console.log('Now connected, connection ID=' + connection.id); })
        .fail(function () { console.log('Could not connect'); });

    return (() => {
      myHubProxy.off('StatusRegisterBiometry');
      connection = hubConnection('http://' + tenant.localApiUrl + ':5001');
    });
  }
  }, [tenant.localApiStatus,tenant.localApiUrl, opened, user])

  const handleCloseModal = useCallback(() => {
    if (onClose) {
      onClose({}, 'backdropClick');
    }
  },[])


  return (
    <Dialog maxWidth="sm" fullWidth aria-describedby="form-dialog-title"  {...rest}>

      <DialogTitle id="confirmation-dialog-title">
        Cadastro de Biometria
        <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseModal}>
            <Close />
          </IconButton>
      </DialogTitle>

      <DialogContent dividers>
        <div className={classes.divInfo}>
          {!saved &&
          <HelpOutline fontSize="small"  className={classes.IconInfo} />
}
     {result}
      </div>
        <div >
          <Grid direction="column"
            justify="center"
            alignItems="center" >

            <Grid item xs={12} >
              <div>
                {!saved &&
                <Lottie options={defaultOptions}
                  height={200}
                  width={200}
                  speed={0.6}
                />
                }
                 {!!saved &&
                <Lottie options={savedOptions}
                  height={200}
                  width={200}
                  speed={0.6}

                />
                }
              </div>



            </Grid>
          </Grid>
        </div>
      </DialogContent>
    </Dialog>
  );
}



export default DialogBiometricRegister;
