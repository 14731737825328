import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, useHistory, useLocation } from 'react-router-dom';
import { createMuiTheme, ThemeProvider, Theme } from '@material-ui/core/styles';
import GlobalStyle from './styles/global';



import AppProvider from './hooks';

import Routes from './routes';
import { Background, Content } from './styles';
import { green, orange, yellow, pink, blue } from '@material-ui/core/colors';
import { useAuth } from './hooks/auth';



const outerTheme = createMuiTheme();
outerTheme.typography.h3 = {

  [outerTheme.breakpoints.up('sm')]: {
    fontSize: '1.5rem',
  },
  [outerTheme.breakpoints.up('md')]: {
    fontSize: '1.6rem',
  },
  [outerTheme.breakpoints.up('lg')]: {
    fontSize: '1.8rem',
  },
}
outerTheme.typography.h4 = {
  fontSize: '1.0rem',
  '@media (min-width:600px)': {
    fontSize: '1.2rem',
  },
  [outerTheme.breakpoints.up('md')]: {
    fontSize: '1.2rem',
  },
}
outerTheme.typography.h6 = {
  fontSize: '1.0rem',
  '@media (min-width:600px)': {
    fontSize: '1.2rem',
  },
  [outerTheme.breakpoints.up('md')]: {
    fontSize: '1.2rem',
  },
}



const App: React.FC = () => {



  return (
    <Router >

          <Content>
            <AppProvider>
              <Routes />
            </AppProvider>
          </Content>

      <GlobalStyle />
    </Router>
  )
};

export default App;
