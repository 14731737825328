import axios from 'axios';

// const api = axios.create({
//     baseURL: "http://localhost:53114/api/v1/",
// });
const api = axios.create({
  baseURL: "https://apihubfitapp.azurewebsites.net/api/v1/",
});

export default api;
