export interface User{
  id: string;
  idTenant: string;
  idBranch: string;
  identifier: string;
  name: string;
  firstName: string;
  email: string;
  avatarURL: string;
  type: number;
}
export enum TypeUser{
  Colaborador,
  Aluno,
  Personal,
  Prospect,
}
