import React, { useCallback, useState, useEffect, useRef, useMemo } from 'react';
import { format, isToday } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import DayPicker, { DayModifiers } from 'react-day-picker';
import 'react-day-picker/lib/style.css'
import classNames from "classnames";
import { useAuth } from '../../../hooks/auth';
import { useHistory, RouteComponentProps } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers';
import * as Yup from 'yup';
import { hubConnection } from "signalr-no-jquery";
import {
  Content, Schedule, NextAppointment, Calendar, Section, Appointment, ContentModal, CalendarModal
} from './styles';
import { createStyles, makeStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import {
  Typography, Button, Grid, Divider, List as ListComponent,
  DialogTitle as MuiDialogTitle, DialogContent as MuiDialogContent, DialogActions as MuiDialogActions, Dialog, InputAdornment, CircularProgress, Input, FormControl, InputLabel, FormHelperText, CardMedia, CardContent, Card, Chip, Paper, Fab, IconButton, Select, MenuItem, ListItem, ListItemIcon, ListItemText
} from "@material-ui/core";
import api from '../../../services/api';
import animationData from '../../../assets/animations/fingerprint-scanner.json';
import animationDataSaved from '../../../assets/animations/success.json';
import { useToast } from '../../../hooks/toast';
import Header from '../../../components/Header';
import Timer from '../../../components/TimerCountdown';
import { parseISO } from 'date-fns';
import { QueryBuilder, Today, Add as AddIcon, Close as CloseIcon, ArrowLeft, ArrowRight, MenuBook, Check, HelpOutline } from '@material-ui/icons';
import { IAppointmentsMonth, IAppointment, IAppointmentDays } from '../../../models/Appointment/IAppointmentDTO';
import { inherits } from 'util';
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import { CardTextHeader } from '../../Login/SignIn/styles';
import CardFooter from '../../../components/Card/CardFooter';
// import './carrousel.css';
import { green, blue } from '@material-ui/core/colors';
import DialogAvatar from '../../../components/DialogAvatar/Index';
import Webcam from 'react-webcam';
import axios from 'axios';
import Lottie from 'react-lottie';
const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });


export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      cursor: 'pointer',
    },
    details: {
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
    },
    vagas: {
      borderRadius: '23px',
      height: '48px',
      fontSize: '32px',

    },
    divVagas: {
      display: 'flex',
      alignItems: 'center',
      width: '100px',
      flexDirection: 'column',
      alignSelf: 'center',
      marginTop: '-16px',
    },
    content: {
      flex: '1 0 auto',
    },
    cover: {
      width: 200,
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    playIcon: {
      height: 38,
      width: 38,
    },
    titleClass: {
      margin: '2px 5px 8px 14px !important',
    },
    timeClass: {
      margin: '0 !important',
      color: theme.palette.text.secondary,
    },
    timeRest: {
      color: theme.palette.text.disabled,
      marginLeft: '10px',
      fontSize: '14px',
    },
    divFlexTime: {
      display: 'inline-flex',
      alignItems: 'flex-end'

    },
    textVagas: {
      color: theme.palette.text.secondary,
    },
    hourTimeAppointments: {
      flexDirection: 'column',
      color: theme.palette.secondary.main,
    },
    dayPicker: {

      backgroundColor: theme.palette.background.paper,
      boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
      borderRadius: '4px',

      '& .DayPicker-Day--available:not(.DayPicker-Day--outside)': {
        background: theme.palette.primary.main,
        borderRadius: '10px',
        color: '#fff',
      },
      '& .DayPicker-Day--available:not(.DayPicker-Day--disabled):hover': {
        background: theme.palette.primary.dark + ' !important',

      },
      '& .DayPicker-Day--today:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)': {
        background: theme.palette.primary.dark + ' !important',
        borderRadius: '10px',
        color: theme.palette.primary.contrastText,
      },
      '& .DayPicker-Day--today.DayPicker-Day--disabled': {
        background: '#ccc !important',
      },
      '& .DayPicker-Day--available.DayPicker-Day--disabled': {
        background: 'transparent',
      },

    },
    dayPickerModal: {

      backgroundColor: theme.palette.background.paper,
      boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
      borderRadius: '4px',

      '& .DayPicker-Day--available:not(.DayPicker-Day--outside)': {
        background: 'rgb(130 130 130)',
        borderRadius: '10px',
        color: '#fff',
      },
      '& .DayPicker-Day--available:not(.DayPicker-Day--outside):not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):hover': {
        background: theme.palette.primary.main + ' !important',

      },
      '& .DayPicker-Day--today:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside)': {
        background: theme.palette.primary.main + ' !important',
        borderRadius: '10px',
        color: theme.palette.primary.contrastText,
      },
      '& .DayPicker-Day--today.DayPicker-Day--disabled': {
        background: '#ccc !important',
      },
      '& .DayPicker-Day--available.DayPicker-Day--disabled': {
        background: 'transparent',
      },
      '& .DayPicker-Day--available.DayPicker-Day--selected:not(.DayPicker-Day--outside)': {
        background: theme.palette.secondary.main + ' !important',
      },
      '& .DayPicker-Day--available.DayPicker-Day--selected:hover:not(.DayPicker-Day--outside):hover': {
        background: theme.palette.secondary.dark + ' !important',
      },

    },
    gridItem: {
      padding: '16px',
    },
    gridItemModal: {
      padding: '28px',
    },
    paperAppointments: {
      padding: '23px',
    },
    serviceItem: {
      backgroundColor: theme.palette.primary.main,
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    margin: {

    },
    RingOff: {
      'outline-style': 'inherit',
    },
    SpanCard: {
      color: 'rgba(0,0,0,0.5)',
      display: 'flex',
      alignItems: 'center',

    },
    wrapper: {
      margin: '60px 0 0',
      width: '100%',
      position: 'relative',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    formControl: {

      minWidth: 266,
    },
    divBack: {
      marginTop: '10px',
    },
    divFoward: {
      marginTop: '10px',
      flexFlow: 'row-reverse',
      flex: '1',
      display: 'flex',
    },
    listHours: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
    titleHours: {
      margin: '18px 6px',
      textAlign: 'center',
    },
    chipHour: {
      fontSize: '1rem',
    },
    divCenter: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    GridList: {
      margin: '20px 0',
    },
    IconInfo: {
      color: blue[500],
      marginRight: '7px'
    },
    divInfo: {
      display: 'flex',
      margin: '10px 0 40px',
      alignItems: 'center',
      flexDirection: 'column',
    },
    videoMask: {
      width: '480px',
      height: '480px',
      borderRadius: '50%',
      overflow: 'hidden',
      margin: '0 auto',
      position: 'relative',
    },
    divButtonCapture: {
      marginBottom: '22px',
    },
    textCountdown: {
      color: theme.palette.primary.main,
      fontSize: '10.2rem',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 100
    },
    divNextButton: {
      textAlign: 'right',
    },
    divButtonBiometricSave: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      marginTop: '30px',
    }
  }),
);
interface PresencaItem {
  Id: number;
  Nome: string;
  Tipo: number;
  Identificador: string;
}

interface IServices {
  id: number;
  name: string;
}
interface IAppointmentRouteData extends RouteComponentProps<
  { myParamProp?: string }, // this.props.match.params.myParamProp
  any, // history
  { myStateProp?: string } // this.props.location.state.myStateProp
  > {
  agendar?: string,
}


const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);
const videoConstraints = {
  width: 640,
  height: 480,
  facingMode: "user"
};

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
};
const savedOptions = {
  loop: false,
  autoplay: true,
  animationData: animationDataSaved,
};

const UserRegistration: React.FC = () => {
  const classes = useStyles();
  const { tenant, user, updateUser } = useAuth();
  const [permitiu, setPermitiu] = useState(false);
  const [salvando, setSalvando] = useState(false);
  const [savedBiometric, setSavedBiometric] = useState(false);
  const [resultBiometric, setResultBiometric] = useState('Coloque o dedo no leitor biométrico');
  const [carregou, setCarregou] = useState(false);
  const [take, setTake] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingAppointments, setLoadingAppointments] = useState(false);
  const [loadingServices, setLoadingServices] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [selectedNewDate, setSelectedNewDate] = useState<Date>();
  const [currentNewMonth, setCurrentNewMonth] = useState<Date>(new Date());
  const [selectedHour, setSelectedHour] = useState();
  const [hoursAvailability, setHoursAvailability] = useState<string[] | undefined>(undefined)
  const [appointments, setAppointments] = useState<IAppointment[]>();
  const [services, setServices] = useState<IServices[]>();
  const [nextEnabled, setNextEnabled] = useState(false);
  const [btnReturnVisible, setBtnReturnVisible] = useState(false);
  const [nextEnabledText, setNextEnabledText] = useState("Pular");
  const [serviceSelected, setServiceSelected] = useState<IServices | undefined>();
  const [openService, setOpenService] = React.useState(false);
  const [appointment_days, setAppointment_days] = useState<IAppointmentDays[]>([]);
  const [appointment_available, setAppointment_available] = useState<IAppointmentDays[]>([]);
  const webcamRef = React.useRef<Webcam & HTMLVideoElement>(null);
  const [imgScreenshot, setImgScreenshot] = useState<string | undefined>();
  const [tab, setTab] = React.useState(0);
  const [openModal, setOpenModal] = React.useState(false);
  const [titleModal, setTitleModal] = React.useState("");
  const { addToast } = useToast();
  const history = useHistory<IAppointmentRouteData>();
  const today = new Date();

  useEffect(() => {


    const habAgend = sessionStorage.getItem('@HubfitTotem:habilitaAgendamento');
    const newUser = sessionStorage.getItem('@HubfitTotem:new_registration');
    console.log(newUser)
    if (newUser == null) {

      setTab(0);
      setTitleModal("Foto");
      setOpenModal(true);
    } else if (habAgend == "true") {
      setTab(2);
      setTitleModal("Agendamento");
      setOpenModal(true);
    } else {
      history.push("/dashboard")
    }

    sessionStorage.removeItem('@HubfitTotem:habilitaAgendamento');
    sessionStorage.removeItem('@HubfitTotem:new_registration');


  }, [user.avatarURL]);

  useEffect(() => {
    if (currentNewMonth && serviceSelected) {
      api.get("services/month-availability", {
        params: {
          year: currentNewMonth.getFullYear(),
          month: currentNewMonth.getMonth() + 1,
          idservice: serviceSelected.id
        }
      }).then((response) => {
        setAppointment_available(response.data);
      })
    }
  }, [currentNewMonth, serviceSelected]);

  const disabledNewDays = useMemo(() => {
    if (currentNewMonth) {
      const dates = appointment_available.filter(monthDay =>
        monthDay.available == false).map(monthDay => {
          const year = currentNewMonth.getFullYear();
          const month = currentNewMonth.getMonth();
          return new Date(year, month, monthDay.day);
        })
      return dates;
    }
  }, [currentNewMonth, appointment_available]);

  const handleMonthNewChange = useCallback((month: Date) => {
    setCurrentNewMonth(month);
  }, []);
  useEffect(() => {
    if (serviceSelected && selectedNewDate) {
      api.get("services/hour-availability", {
        params: {
          date: format(selectedNewDate, 'yyyy/MM/dd'),
          idservice: serviceSelected.id
        }
      }).then((response) => {
        setHoursAvailability(response.data);
      })

    }
  }, [serviceSelected, selectedNewDate]);
  const handleDateNewChange = useCallback(async (day: Date, modifier: DayModifiers) => {
    if (modifier.available && !modifier.disabled && serviceSelected) {
      setSelectedNewDate(day);
    }
  }, [serviceSelected])

  useEffect(() => {
    if (tab == 1) {
      setSavedBiometric(false);
      setResultBiometric('Coloque o dedo no leitor biométrico');
      if (tenant.localApiStatus == true) {
        axios.get('http://' + tenant.localApiUrl + ':5001/api/biometrics/registermode', {
          params: {
            id: user.id,
            identifier: user.identifier,
            type: user.type,
          }
        }).catch((err) => {
          addToast({
            type: "error",
            title: 'Erro',
            description: "Não foi possível ativar o leitor para iniciar o registro",
          });
        })

        let connection = hubConnection('http://' + tenant.localApiUrl + ':5001');
        const myHubProxy = connection.createHubProxy('ServerHub');


        myHubProxy.on('StatusRegisterBiometry', function (message) {
          console.log('status: ', message)
          if (message) {
            console.log(message)
            try {
              if (message.success == true) {
                setSavedBiometric(true);
                setResultBiometric(message.msg);
                axios.get('http://' + tenant.localApiUrl + ':5001/api/biometrics/readermode').catch((err) => {
                })
              } else {
                setSavedBiometric(false);
                setResultBiometric(message.msg);
              }
            } catch (err) {

            }
          }
        });
        connection.start({ jsonp: true })
          .done(function () { console.log('Now connected, connection ID=' + connection.id); })
          .fail(function () { console.log('Could not connect'); });

        return (() => {
          myHubProxy.off('StatusRegisterBiometry');
          connection = hubConnection('http://' + tenant.localApiUrl + ':5001');
        });
      }
    }
  }, [tenant.localApiStatus, tenant.localApiUrl, tab, user])

  useEffect(() => {

    if (tab == 2) {
      setLoadingServices(true);
      api.get('services', {
        params: {
          idbranch: tenant.idBranch
        }
      }).then(response => {
        setServices(response.data);
        setTimeout(() => {
          setOpenService(true);
          setLoadingServices(false);
        }, 1000);


      }).catch(err => {
        addToast({
          type: "error",
          title: 'Erro',
          description: 'Erro na conexão.',
        });
        setLoadingServices(false);
      });
    }
  }, [tenant.idBranch, tab]);

  const handleCloseModal = useCallback(() => {
    history.push("/dashboard");
  }, [])
  useEffect(() => {
    switch (tab) {
      case 0:
        setTitleModal("Foto");
        setNextEnabled(true);
        setNextEnabledText("Pular");
        setBtnReturnVisible(false);
        break;
      case 1:
        setTitleModal("Biometria");
        setNextEnabled(true);
        setNextEnabledText("Pular");
        break;
      case 2:
        setTitleModal("Agendamento");
        setNextEnabled(false);
        setNextEnabledText("Próximo");
        break;
      case 3:
        setBtnReturnVisible(true);
        break;
      case 4:
        setNextEnabled(false);
        break;
    }
  }, [tab])
  const handleTabNext = useCallback(() => {
    if (tab == 0 && (tenant?.fingerprintReader == false || tenant?.fingerprintReader == null || tenant?.localApiStatus == false)) {
      setTab(tab + 2);
    }
    else {
      setTab(tab + 1);
    }
  }, [tab])
  const handleTabReturn = useCallback(() => {
    if (tab == 0 && (tenant?.fingerprintReader == false || tenant?.fingerprintReader == null || tenant?.localApiStatus == false)) {
      setTab(tab - 2);
    }
    else {
      setTab(tab - 1);
    }
  }, [tab]);
  const handleServiceClose = useCallback(() => {
    setOpenService(false);

  }, [])
  const handleChangeService = useCallback((event: React.ChangeEvent<{ value: unknown }>) => {
    console.log(services?.filter((item) => item.id == event.target.value)[0])
    setServiceSelected(services?.filter((item) => item.id == event.target.value)[0]);
    setNextEnabled(true);

    setTab(3);
  }, [services])
  const handleServiceOpen = useCallback(() => {
    setOpenService(true);
  }, []);
  const handleHourClick = useCallback((item) => {
    setSelectedHour(item);
    setTab(4);
  }, [])
  const handleClickSaveAppointment = useCallback(async () => {
    setLoadingSave(true);
    if (serviceSelected && selectedNewDate && selectedHour) {
      try {
        const response = await api.post('appointments', {
          idservice: serviceSelected.id,
          date: format(selectedNewDate, 'yyyy/MM/dd'),
          hour: selectedHour,
        });
        if (response.data.ok) {
          addToast({
            type: "success",
            title: 'Agendamento Salvo',
            description: 'Seu agendamento foi realizado com sucesso!',
          });
          setOpenModal(false);
          history.push("/dashboard")
        }
      } catch (err) {

        addToast({
          type: "error",
          title: 'Erro',
          description: 'Erro na conexão. Não foi possível agendar',
        });
      } finally {
        setLoadingSave(false);
      }
    }
  }, [serviceSelected, selectedNewDate, selectedHour])

  //#region Imagem
  const capture = React.useCallback(() => {

    setTake(false);
    setImgScreenshot(undefined);
    var timeleft = 5;
    const obj = document.getElementById("countdown");
    if (obj) {
      console.log(timeleft)
      obj.innerHTML = timeleft + "";
    }
    var downloadTimer = setInterval(function () {
      timeleft -= 1;
      if (timeleft <= 0) {
        clearInterval(downloadTimer);

        const obj = document.getElementById("countdown");
        if (obj) {
          console.log("0")
          obj.innerHTML = "";
        }
        if (webcamRef != null) {
          if (webcamRef.current != null) {


            const imageSrc = webcamRef.current.getScreenshot();
            if (imageSrc) {
              setTake(true);
              setImgScreenshot(imageSrc);
            }
            console.log(imageSrc);
          }
        }
      } else {
        const obj = document.getElementById("countdown");
        if (obj) {
          console.log(timeleft)
          obj.innerHTML = timeleft + "";
        }
      }


    }, 1000);


  },
    [webcamRef]
  );
  const saveImage = useCallback(() => {
    if (imgScreenshot) {
      setSalvando(true);
      const data = new FormData();
      data.append('avatar', imgScreenshot);

      api.post('/users/avatar', data).then(response => {
        console.log(response.data);
        user.avatarURL = response.data;
        updateUser(user, null);
        addToast({
          type: 'success',
          title: 'Foto atualizada',
        });
        setTake(false);
        setImgScreenshot(undefined);
        setSalvando(false);

        if (tenant?.fingerprintReader == false || tenant?.fingerprintReader == null || tenant?.localApiStatus == false) {
          setTab(tab + 2);
        } else {
          setTab(1);
        }


      })
    }

  }, [imgScreenshot, user, history]);
  const errorVideo = useCallback(() => {
    setCarregou(true)
    setPermitiu(false);
  }, [setPermitiu])
  const Video = useCallback(() => {
    console.log('video')
    setCarregou(true)
    setPermitiu(true);
  }, [setPermitiu])
  //#endregion

  return (
    <>
      <Header isBack avatar />





      <Dialog maxWidth="sm" fullWidth onClose={handleCloseModal} aria-describedby="form-dialog-title" open={openModal}>

        <DialogTitle id="form-dialog-title" onClose={handleCloseModal}>
          <Typography variant="h6">{titleModal}
            {serviceSelected &&
              <span>{` de ${serviceSelected.name}`}</span>
            }
          </Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <ContentModal>
          <Grid direction="column"
            justify="center"
            alignItems="center" >
            <Grid item xs={12} >
              <Grid container
                direction="row"
                justify="space-between"
                alignItems="center" >
                <Grid item xs={6} >
                  {btnReturnVisible &&
                    <Button onClick={handleTabReturn}>
                      <ArrowLeft />
                Voltar
            </Button>
                  }
                </Grid>
                <Grid item xs={6} className={classes.divNextButton}>
                  {tab < 3 &&
                    <Button disabled={!nextEnabled} onClick={handleTabNext} >
                      {nextEnabledText}
                      <ArrowRight />
                    </Button>
                  }
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} >
              <CarouselProvider
                disableKeyboard
                currentSlide={tab}
                touchEnabled={false}
                dragEnabled={false}
                isIntrinsicHeight={true}
                naturalSlideWidth={300}
                naturalSlideHeight={400}

                totalSlides={5}
              >
                <Slider >
                  <Slide index={0} >
                    {!permitiu && carregou &&
                      <div className={classes.divInfo}>
                        <HelpOutline fontSize="small" className={classes.IconInfo} />
          Nenhuma webcam instalada ou não foi permitido a utilização neste navegador.
      </div>
                    }
                    <div >
                      <Grid direction="column"
                        justify="center"
                        alignItems="center" >
                        <Grid item xs={12} >
                          <Grid container direction="row" spacing={2}
                            justify="center"
                            alignItems="center" className={classes.divButtonCapture}>
                            {permitiu && carregou &&
                              <Grid item xs={6} >

                                <Button disabled={salvando} fullWidth color="primary" variant="contained" onClick={capture}>Tirar foto</Button>
                              </Grid>
                            }
                            {take &&
                              <Grid item xs={6} >
                                <Button disabled={salvando} fullWidth color="primary" variant="outlined" onClick={saveImage}>Salvar
                    {salvando && <CircularProgress size={24} className={classes.buttonProgress} />}
                                </Button>
                              </Grid>
                            }
                          </Grid>
                        </Grid>
                        <Grid item xs={12} >
                          <div className={classes.videoMask}>
                            <div className={classes.textCountdown} id="countdown"></div>
                            {!take &&
                              <Webcam
                                audio={false}
                                height={480}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                width={640}
                                videoConstraints={videoConstraints}
                                onUserMediaError={errorVideo}
                                onUserMedia={Video}
                                mirrored={true}
                              />
                            }
                            {take &&
                              <img src={imgScreenshot} alt="" />
                            }
                          </div>

                        </Grid>
                      </Grid>
                    </div>
                  </Slide>
                  <Slide index={1} >
                    <div className={classes.divInfo}>
                      {!savedBiometric &&
                        <HelpOutline fontSize="small" className={classes.IconInfo} />
                      }
                      {resultBiometric}
                    </div>
                    <div >
                      <Grid direction="column"
                        justify="center"
                        alignItems="center" >

                        <Grid item xs={12} >
                          <div>
                            {!savedBiometric &&
                              <Lottie options={defaultOptions}
                                height={200}
                                width={200}
                                speed={0.6}
                              />
                            }
                            {!!savedBiometric &&
                              <Lottie options={savedOptions}
                                height={200}
                                width={200}
                                speed={0.6}

                              />
                            }
                          </div>
                          {!!savedBiometric &&
                            <div className={classes.divButtonBiometricSave}>
                              <Button disabled={loadingSave} type="button" onClick={handleTabNext} variant="contained" color="primary" size="large" endIcon={<ArrowRight />}>
                                Continuar
                          </Button>
                            </div>
                          }
                        </Grid>
                      </Grid>
                    </div>
                  </Slide>
                  <Slide index={2} >

                    <div className={classes.divCenter}>

                      <CardTextHeader>
                        <Typography variant="h4" color="primary">Selecione o serviço</Typography>
                      </CardTextHeader>

                      {loadingServices &&

                        <Grid container direction="column" className={classes.GridList}
                          justify="center"
                          alignItems="center" >
                          <Grid item xs={12} >
                            <CircularProgress size={26} />
                          </Grid>
                    Buscando serviços disponíveis
                  </Grid>
                      }
                      {!loadingServices &&
                        <FormControl className={classes.formControl}>
                          <InputLabel id="servico">Serviço</InputLabel>


                          <Select
                            labelId="servico"
                            id="servico"
                            open={openService}
                            onClose={handleServiceClose}
                            onOpen={handleServiceOpen}
                            value={serviceSelected}
                            onChange={handleChangeService}
                          >
                            {services?.map((item) => (
                              <MenuItem value={item.id} >{item.name}</MenuItem>
                            ))}

                          </Select>
                        </FormControl>
                      }

                    </div>

                  </Slide>
                  <Slide innerClassName={classes.RingOff} index={3} >

                    <div className={classes.divCenter}>
                      <CardTextHeader>
                        <Typography variant="h4" color="primary">Selecione o dia e horário disponível</Typography>

                      </CardTextHeader>
                      {tab == 3 &&
                        <>
                          <CalendarModal>
                            <DayPicker className={classes.dayPickerModal}
                              weekdaysShort={['D', 'S', 'T', 'Q', 'Q', 'S', 'S',]}
                              fromMonth={new Date()}
                              disabledDays={disabledNewDays}
                              selectedDays={selectedNewDate}
                              onMonthChange={handleMonthNewChange}
                              modifiers={
                                { available: { daysOfWeek: [0, 1, 2, 3, 4, 5, 6] } }
                              }
                              onDayClick={handleDateNewChange}
                              months={[
                                'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
                              ]}
                            />
                          </CalendarModal>
                          {hoursAvailability && hoursAvailability.length > 0 && selectedNewDate &&
                            <>
                              <h3 className={classes.titleHours}>Horários disponíveis para {format(selectedNewDate, 'dd/MMM', { locale: ptBR })}</h3>
                              <div className={classes.listHours}>

                                {hoursAvailability?.map((item) => (
                                  <Chip className={classes.chipHour} clickable onClick={() => handleHourClick(item)} key={item} color="primary" label={`${item}h`} />
                                ))}
                              </div>
                            </>
                          }
                          {hoursAvailability && hoursAvailability.length == 0 && selectedNewDate &&
                            <div className={classes.listHours}>
                              <h3 className={classes.titleHours}>Nenhum horário disponível para {format(selectedNewDate, 'dd/MMM', { locale: ptBR })}</h3>
                            </div>
                          }
                        </>

                      }
                    </div>


                  </Slide>
                  <Slide innerClassName={classes.RingOff} index={4} >

                    <div className={classes.divCenter}>

                      <ListComponent component="nav" aria-label="main mailbox folders">
                        <ListItem button>
                          <ListItemIcon>
                            <MenuBook color="primary" />
                          </ListItemIcon>
                          <ListItemText primary={serviceSelected?.name} />
                        </ListItem>
                        <ListItem button>
                          <ListItemIcon>
                            <Today color="primary" />
                          </ListItemIcon>
                          {selectedNewDate &&
                            <ListItemText primary={format(selectedNewDate, "dd 'de' MMMM 'de' yyyy", { locale: ptBR })} />
                          }
                        </ListItem>
                        <ListItem button>
                          <ListItemIcon>
                            <QueryBuilder color="primary" />
                          </ListItemIcon>
                          <ListItemText primary={`${selectedHour}h`} />
                        </ListItem>
                      </ListComponent>
                      <Button disabled={loadingSave} type="button" onClick={handleClickSaveAppointment} variant="contained" color="primary" size="large" endIcon={<Check />}>
                        Confirmar
                              {loadingSave && <CircularProgress size={24} className={classes.buttonProgress} />}
                      </Button>





                    </div>

                  </Slide>
                </Slider>

              </CarouselProvider>
            </Grid>
          </Grid>
        </ContentModal>
      </Dialog>
    </>
  )

}



export default UserRegistration;
