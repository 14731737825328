import styled from 'styled-components';




export const Container = styled.div`
    position: fixed;
    right: 0px;
    top: 0px;
    padding: 14px;
    overflow: hidden;
    z-index: 1400;
`;

