import React, { useState, useCallback, useEffect } from "react";



// nodejs library to set properties for components

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Dialog, DialogTitle, DialogContent, Grid,  createStyles, Theme, DialogProps, Backdrop, CircularProgress } from "@material-ui/core";
import { useToast } from "../../hooks/toast";
import { useAuth } from '../../hooks/auth';
import { Guid } from "guid-typescript";
import { HelpOutline } from "@material-ui/icons";
import { blue } from "@material-ui/core/colors";
import { IClassItem } from "../../models/Classes/IClassesDTO";
import { useHistory } from "react-router-dom";
import animationData from '../../assets/animations/fingerprint-scanner.json';
import Lottie from "react-lottie";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      cursor: 'pointer',
    },
    message: {
      margin: '15px 0',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    searching: {
      display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    },
      IconInfo: {
        color: blue[500],
        marginRight: '7px'
      },
      divInfo:{
        display: 'flex',
    margin: '10px 0',
      }
  })
);

type BiometricProps = DialogProps &{
  aula?: IClassItem;
  isSearching: boolean;
}
const DialogBiometric: React.FC<BiometricProps> = ({aula, isSearching , ...rest }) => {
  const classes = useStyles();
  const [ connection, setConnection ] = useState<SignalR.Hub.Connection>();
  const [result, setResult] = useState('sem resultado');
  const [reading, setReading] = useState(false);
  const { addToast } = useToast();
  const { signInQrCode } = useAuth();
  const history = useHistory();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  };


  return (
    <Dialog  maxWidth="sm" fullWidth aria-describedby="form-dialog-title"  {...rest}>

      <DialogTitle id="confirmation-dialog-title">Leitura de Biometria</DialogTitle>

      <DialogContent dividers>
  <div className={classes.divInfo}>
      <HelpOutline fontSize="small" className={classes.IconInfo} />
     Coloque o dedo no leitor biométrico
      </div>
        <div >
          <Grid direction="column"
            justify="center"
            alignItems="center" >

            <Grid item xs={12} >

            <Lottie options={defaultOptions}
                  height={200}
                  width={200}
                  speed={0.6}
                />


            </Grid>
          </Grid>
        </div>
      </DialogContent>
    </Dialog>
  );
}



export default DialogBiometric;
