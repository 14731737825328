import React, { useCallback, useEffect, useState, useRef, ChangeEvent } from 'react';
import { hubConnection, Proxy, Connection } from 'signalr-no-jquery';
import { Button, Grid, Typography, IconButton, makeStyles, Card, CardContent, CardMedia, Chip, Paper, Hidden, DialogContent, Dialog, DialogTitle, FormControl, FormHelperText, InputLabel, CircularProgress, Input, Avatar, InputAdornment, Backdrop, Zoom, Toolbar, useScrollTrigger, Fab, Divider, Badge, TextField } from '@material-ui/core';
import { createStyles, Theme, useTheme } from '@material-ui/core/styles';
import './Classes.css';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import { Content, CardTextHeader } from './styles';
import animationData from '../../assets/animations/fingerprint-scanner.json';
import animationDataSuccess from '../../assets/animations/success.json';
import animationDataError from '../../assets/animations/error.json';

import { AssignmentInd, AccountBox, CropFree, HelpOutline, Email, Create, Fingerprint, KeyboardArrowUp, FormatListNumbered, Close as CloseIcon, ArrowForward, ArrowForwardOutlined, ArrowForwardIos, ArrowLeft } from '@material-ui/icons';

import { Skeleton } from '@material-ui/lab';
import api from '../../services/api';
import { IClassItem, IVerify, IReturnVerify, MapaNode } from '../../models/Classes/IClassesDTO';
import { setDay, isBefore, addDays, format } from 'date-fns';
import { red, green, blue } from '@material-ui/core/colors';
import Timer from '../../components/TimerCountdown';

import logo from '../../assets/img/logo-hubfit.svg';
import { CarouselProvider, Slider, Slide } from 'pure-react-carousel';
import CardBody from '../../components/Card/CardBody';
import { yupResolver } from '@hookform/resolvers';
import { useForm } from 'react-hook-form';
import { User } from '../../models/Tenant/UserDTO';
import DialogQrCode from '../../components/QRCode/Index';
import DialogBiometric from '../../components/Biometric/Index';
import Lottie from 'react-lottie';
import classNames from 'classnames';
import Keyboard from "react-simple-keyboard";
import InputMask from "react-input-mask";
import "react-simple-keyboard/build/css/index.css";
import KeyboardWrapper from '../../components/Keyboard/KeyboardWrapper';
// import { CountdownCircleTimer } from 'react-countdown-circle-timer'


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      cursor: 'pointer',
    },

    mainRaised: {
      margin: "0px 30px 0px",
      borderRadius: "6px",
    },
    margin: {
      margin: '8px 0',
      padding: '23px 55px',
      '& span': {
        display: 'flex !important',
        flexDirection: 'column !important',
        '& .MuiSvgIcon-root': {
          fontSize: '2.5rem',
        }
      }
    },
    rootLoading: {
      display: 'flex',
      cursor: 'default',
      opacity: '0.8',
    },
    buttonProgress: {
      color: green[500],
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    details: {
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
    },
    vagas: {
      borderRadius: '23px',
      height: '48px',
      [theme.breakpoints.up('xs')]: {
        fontSize: '18px',
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: '20px',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '22px',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: '23px',
      },





    },
    divVagas: {
      display: 'flex',
      alignItems: 'center',
      width: '89px',
      flexDirection: 'column',
      alignSelf: 'center',
      // marginTop: '-16px',
    },
    IconInfo: {
      color: blue[500],
    },
    wrapper: {
      margin: '42px 0 0',
      width: '100%',
      position: 'relative',
    },
    RingOff: {
      'outline-style': 'inherit',
    },
    carrouselInvisible: {
      display: 'none',
    },
    content: {
      flex: '1 0 auto',
      padding: '7px !important',
    },
    cover: {
      width: 200,
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    playIcon: {
      height: 38,
      width: 38,
    },
    titleClass: {
      margin: '2px 5px 2px 14px !important',
    },
    timeClass: {
      margin: '0 !important',
      color: theme.palette.text.secondary,
    },
    timeClassDiv: {
      maxWidth: '103px'
    },
    timeRest: {
      color: theme.palette.text.disabled,
      marginLeft: '10px',
      fontSize: '14px',
    },
    divFlexTime: {
      display: 'flex',
      alignItems: 'flex-end',
      flex: 1,

    },
    flexOne: {
      flex: 1,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    textVagas: {
      color: theme.palette.text.secondary,
    },
    textConfirmado: {
      background: green[500],
      height: '32px',
      fontSize: '19px',
      borderRadius: '23px',
    },
    coverModal: {
      width: 'auto',
      height: '185px',
    },
    titleLigh: {
      color: theme.palette.text.disabled,
    },
    titleCoverModal: {
      position: 'absolute',
      top: '30%',
      left: '50%',
      color: 'white',
      transform: 'translate(-50%, -50%)',
      textShadow: '1px 2px 4px #000000',
    },
    contentModal: {
      position: 'relative',
    },
    btnRegister: {
      position: 'absolute',
      right: '15px',
      top: '-18px',
    },
    cardText: {
      textAlign: 'center',
    },

    section2: {
      margin: theme.spacing(2),
    },
    buttonRed: {
      color: theme.palette.getContrastText(red[500]),
      background: red[500],
      '&:hover': {
        backgroundColor: red[700],
      },
    },
    disableRegister: {
      color: 'rgb(0 0 0 / 55%)',
      backgroundColor: 'rgb(189 189 189 / 74%)',
      '&:hover': {
        backgroundColor: 'rgb(189 189 189 / 74%)',
      },
      cursor: 'inherit',
    },
    noClasses: {
      padding: '60px',
      opacity: '0.7',
      '& h5': {
        color: theme.palette.text.secondary,
      }
    },
    imgLogo: {

      maxWidth: '310px',
      height: 'auto',
      width: '100%',
    },
    containerLogo: {
      textAlign: 'center',
      marginTop: '15px',
    },
    btnItemLoginComponent: {
      marginBottom: '29px',
    },
    textItemLogin: {
      margin: '26px 0',
    },
    textCenter: {
      textAlign: 'center',
    },
    btnItemLogin: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',

    },
    dividerInset: {
      marginBottom: '20px',

    },
    dividerLine: {
      marginTop: '20px',
    },
    backToTopAnchor: {
      height: '0px',
      minHeight: '0px',
    },
    SpanCard: {
      color: 'rgba(0,0,0,0.5)',
      display: 'flex',
      alignItems: 'center',

    },
    rootAvatar: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    avatarLarge: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
    large: {
      width: theme.spacing(14),
      height: theme.spacing(14),
    },
    avatar: {
      maxWidth: "160px",
      margin: "0 auto",
    },
    titleClasses: {
      fontSize: '23px',
      marginBottom: '10px',
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    profile: {
      textAlign: "center",

    },
    avatarDiv: {
      position: 'relative',
      alignSelf: 'center',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: 4,
      color: theme.palette.grey[500],
    },
    input:{

    }
  })
);
interface FindCPFFormData {
  cpf: string;
}
interface FindCodigoFormData {
  codigo: string;
  nascimento: string;
}
interface SignInFormData {
  password: string;
}
interface IUserLogin {
  avatarUrl: string;
  email: string;
  nome: string;
  id: number;
  tipoPessoa: number;
  cadastroProspect: boolean;
  cadastro: number;
}
enum SegundoPasso {
  cadastroSenha,
  loginSenha,
}
interface SignUpFormData {
  password: string;
  password_confirmation: string;
  email: string;
}
interface IFormCreateLogin {
  id: number;
  email: string;
  senha: string;
  tipoPessoa: number;
  idTenant: string;
}

interface PropsZoom {
  window?: () => Window;
  children: React.ReactElement;
}

const useStylesZoom = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  }),
);

function ScrollTop(props: PropsZoom) {
  const { children, window } = props;
  const classes = useStylesZoom();
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  return (
    <Zoom in={trigger} style={{ zIndex: 999 }}>
      <div onClick={handleClick} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}


const Home: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { tenant, user, signOut, signIn, updateUser, signInQrCode } = useAuth();

  const history = useHistory();

  const [loadingClasses, setLoadingClasses] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingDigital, setLoadingDigital] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalQrCode, setOpenModalQrCode] = useState<boolean>(false);
  const [openModalBiometric, setOpenModalBiometric] = useState<boolean>(false);
  const [openModalLogin, setOpenModalLogin] = React.useState(false);
  const [openModalLoginCode, setOpenModalLoginCode] = React.useState(false);
  const [listAulas, setListAulas] = useState<IClassItem[]>();
  const [tab, setTab] = React.useState(0);
  const [tabCode, setTabCode] = React.useState(0);
  const [aulaSelected, setAulaSelected] = useState<IClassItem>();
  const [userTemp, setUserTemp] = React.useState<IUserLogin>();
  const [loadClass, setLoadClass] = React.useState<boolean>(true);
  const [segundoPasso, setSegundoPasso] = React.useState<SegundoPasso>(SegundoPasso.loginSenha);
  const { addToast } = useToast();
  const [isStoppedSuccess, setIsStoppedSuccess] = React.useState(true);
  const [isStoppedError, setIsStoppedError] = React.useState(true);
  const [titleModal, setTitleModal] = useState("Escolha uma opção de login");
  const [textSuccessModal, setTextSuccessModal] = useState("");
  const [verificacao, setVerificacao] = useState<IReturnVerify | undefined>();
  const [posicao, setPosicao] = React.useState<MapaNode | null>(null);
  const [startCountClose, setStartCountClose] = useState<boolean>(false);
  const [startCountCloseCode, setStartCountCloseCode] = useState<boolean>(false);
  const cpfRef = useRef<HTMLInputElement | null>(null);
  const passwordRef = useRef<HTMLInputElement | null>(null);
  const emailSignupRef = useRef<HTMLInputElement | null>(null);
  const codigoRef = useRef<HTMLInputElement | null>(null);
  const nascimentoRef = useRef<HTMLInputElement | null>(null);

  const [larguraModal, setLarguraModal] = useState<false | "xs" | "sm" | "md" | "lg" | "xl" | undefined>();

  const [inputCPF, setInputCPF] = useState("");
  const [inputSenha, setInputSenha] = useState("");
  const [inputCriarSenha, setInputCriarSenha] = useState("");
  const [layout, setLayout] = useState("default");


  const [keyboardVisibilityCPF, setKeyboardVisibilityCPF] = useState(false);
  const [keyboardVisibilitySenha, setKeyboardVisibilitySenha] = useState(false);
  const [keyboardVisibilityCriarSenha, setKeyboardVisibilityCriarSenha] = useState(false);
  const keyboardCPF = useRef(null);
  const keyboardSenha = useRef(null);
  const keyboardCriarSenha = useRef(null);
  const customLayout = {
    default: [
      "1 2 3",
      "4 5 6",
      "7 8 9",
      " 0 ",
      "{bksp}"
    ]
  };



  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  };
  const defaultOptionsSuccess = {
    loop: false,
    autoplay: true,
    animationData: animationDataSuccess,
  };
  const defaultOptionsError = {
    loop: false,
    autoplay: true,
    animationData: animationDataError,
  };
  // const [hubConnectionState, setHubConnectionState] = useState<Connection>();






  useEffect(() => {
    if (user) {
      signOut();
    }

  }, [user]);



  useEffect(() => {



    if (tenant.localApiStatus == true) {
      var connect = hubConnection('http://' + tenant.localApiUrl + ':5001');
      console.log(connect)
      connect.disconnected(() => {
        setTimeout(function () {
          console.log("conectando websocket")
          connect.start({ jsonp: true })
            .done(function () { console.log('Now connected, connection ID=' + connect.id); })
            .fail(function () { console.log('Could not connect'); });
        }, 5000); // Restart connection after 5 seconds.
      })
      const proxy = connect.createHubProxy('ServerHub')
      const createHubConnection = async () => {

        //  setHubConnectionState(connect);

        proxy.on('SendBiometry', (message) => {
          console.log('bio', message)
          if (message) {

            try {
              console.log(aulaSelected)
              signInQrCode(message);

              if (aulaSelected) {
                sessionStorage.setItem('@HubfitTotem:class', JSON.stringify(aulaSelected));
                sessionStorage.setItem('@HubfitTotem:action', "class_register");
                history.push('/classes/list')
              }
            } catch (err) {

            } finally {
              setLoadingDigital(false);
            }
          } else {
            setLoadingDigital(false);
            addToast({
              type: "error",
              title: 'Digital não reconhecida',
            });
          }
        });
        proxy.on('StatusOnFinger', (ativou) => {
          console.log(ativou)
          if (ativou) {
            setLoadingDigital(true);
          } else {
            setLoadingDigital(false);
          }
        });

        console.log('iniciou')
        connect.start({ jsonp: true })
          .done(function () { console.log('Now connected, connection ID=' + connect.id); })
          .fail(function () { console.log('Could not connect'); });
      }
      createHubConnection();

      return (() => {
        proxy.off('StatusOnFinger');
        proxy.off('SendBiometry');
        // connect = hubConnection('http://'+tenant.localApiUrl+':5001');
      });

    }

  }, [aulaSelected, tenant.localApiStatus, tenant.localApiUrl])

  // const cleanUpFunction = useCallback(() => {
  //   console.log(hubConnectionState)
  //   const teste = hubConnectionState?.proxies[0] as Proxy;

  // },[hubConnectionState])

  const getClasses = useCallback(() => {

    api.get('/classes/totem', {
      params: {
        idTenant: tenant.idTenant,
        idTotem: tenant.idTotem,
      }
    }).then(response => {
      setLoadingClasses(false);
      setListAulas(response.data);
    })
  }, []);




  useEffect(() => {
    getClasses();
    let timerId = 0;
    function myLoop() {
      //  if (loadClass) {       //  create a loop function
      const timer = () => setTimeout(function () {   //  call a 3s setTimeout when the loop is called
        getClasses();
        myLoop();             //  ..  again which will trigger another
      }, 60000);
      timerId = timer();

      // }
    }
    myLoop();

    return () => {
      clearTimeout(timerId);
    };

  }, []);



  let RegisterSignInPasswordSchema = Yup.object().shape({
    password: Yup.string().required('Digite a senha'),
  })
  let RegisterSignInCPFSchema = Yup.object().shape({
    cpf: Yup.string().required('Digite o CPF'),
  });
  let RegisterSignInCodigoSchema = Yup.object().shape({
    codigo: Yup.string().required('Digite sua matrícula'),
    nascimento: Yup.string().required('Digite sua data de nascimento'),
  });
  let RegisterSignUpPasswordSchema = Yup.object().shape({
    password: Yup.string().required('Senha obrigatória'),
    // password_confirmation: Yup.string().oneOf(
    //   [Yup.ref('password'), undefined], 'Senha diferente a primeira',
    // )
  })
  const formCpf = useForm<FindCPFFormData>({
    resolver: yupResolver(RegisterSignInCPFSchema)
  });
  const formCodigo = useForm<FindCodigoFormData>({
    resolver: yupResolver(RegisterSignInCodigoSchema)
  });
  const formPassword = useForm<SignInFormData>({
    resolver: yupResolver(RegisterSignInPasswordSchema)
  });
  const formPasswordSignUp = useForm<SignUpFormData>({
    resolver: yupResolver(RegisterSignUpPasswordSchema)
  });

  const handleClickBuy = useCallback(() => {
    setLoadClass(false);
    history.push('/plans/list');
  }, [])
  const handleClickClient = useCallback(() => {
    setAulaSelected(undefined);
    setOpenModal(true);
    setTitleModal("Escolha uma opção de login")
  }, [])

  const handleTabReturn = useCallback(() => {
    setTitleModal("Escolha uma posição")
    setTabCode(0);

  }, [])



  const handleClickSignInCPF = useCallback(() => {
    setTitleModal("Faça login");
    setOpenModalLogin(true);

    setTimeout(() => {

      cpfRef.current?.focus();


    }, 800)
  }, [])
  const handleClickSignInQRCode = useCallback(() => {
    setOpenModal(false);
    setOpenModalQrCode(true);
  }, [])
  const handleClickSignInCode = useCallback(() => {
    console.log(aulaSelected);
    if (aulaSelected) {

      if (aulaSelected.PossuiMapa) {
        verifyAulaSemToken(aulaSelected);
      } else {
        setTabCode(1);

        setOpenModalLogin(false);
        setOpenModalLoginCode(true);
        setTitleModal("Digite sua matrícula e nascimento")
        setTimeout(() => {
          codigoRef.current?.focus();
        }, 800)
      }
    }
    // setOpenModal(true);
    // setOpenModalLogin(false);
    // setOpenModalLoginCode(true);
    // setTimeout(() => {
    //   codigoRef.current?.focus();
    // }, 800)
    // setTabCode(0);
    // setIsStoppedSuccess(true);
    // setTitleModal("Digite sua matrícula e nascimento");
  }, [aulaSelected])
  const handleClickSignInFingerprint = useCallback(() => {
    setOpenModal(false);
    setOpenModalBiometric(true);
  }, [])
  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
    setAulaSelected(undefined);
    setUserTemp(undefined);
    setOpenModalLogin(false);
    setOpenModalLoginCode(false);
    setTab(0);
    setTabCode(0);
    setUserTemp(undefined);
    setVerificacao(undefined);
    setPosicao(null);
    setStartCountClose(false);
  }, [])
  const handleCloseModalQRCode = useCallback(() => {
    setOpenModal(true);
    setOpenModalQrCode(false);
    setOpenModalBiometric(false);

  }, []);
  const handleCloseModalFingerprint = useCallback(() => {
    setOpenModal(true);
    setOpenModalBiometric(false);

    setOpenModalQrCode(false);
  }, []);

  const verifyAulaSemToken = useCallback((aula: IClassItem) => {

    if (aula) {
      setLoading(true);
      api.get("/checkin/verify_no_token", {
        params: {
          idAula: aula.IdAula,
          idAulaGrade: aula.IdGrade,
          horaInicio: aula.HorarioInicio,
          data: getDataSemana(aula.DiaSemana),
          idTenant: tenant.idTenant
        }
      }).then((response) => {
        console.log(response.data);
        var data = response.data;
        setTabCode(0);
        setVerificacao(data);
        setTitleModal("Escolha uma posição")
        setOpenModal(true);
        setOpenModalLogin(false);
        setOpenModalLoginCode(true);

        var elements = document.querySelectorAll('.resize-drag');
        for (var i = 0; i < elements.length; i++) {
          console.log('foiii', elements[i].getAttribute("data-x"));
          (elements[i] as HTMLElement).style.transform = 'translate(' + elements[i].getAttribute("data-x") + 'px,' + elements[i].getAttribute("data-y") + 'px)';
        }

      });
      setLoading(false);
    }
  }, [tenant])

  const handleClickAula = useCallback((id: number) => {


    const aula = listAulas?.find((e) => e.IdGrade == id);


    if (aula) {
      setAulaSelected(aula);
      if (aula.PossuiMapa && tenant.auth_code && tenant.auth_cpf == false && tenant.auth_qrcode == false) {

        verifyAulaSemToken(aula);
      } else {
        setTabCode(1);
        if (tenant.auth_code && tenant.auth_cpf == false && tenant.auth_qrcode == false) {
          setOpenModalLogin(false);
          setOpenModalLoginCode(true);
          setTitleModal("Digite sua matrícula e nascimento")
          setTimeout(() => {
            codigoRef.current?.focus();
          }, 800)
        } else {
          setTitleModal("Escolha uma opção de login");
        }

        setOpenModal(true);



      }
    }
  }, [setOpenModal, setAulaSelected, listAulas, tenant]);


  function parseTime(t: string) {

    var d = new Date();

    var time = t.match(/(\d+)(?::(\d\d))?\s*(p?)/);
    if (time) {
      d.setHours(parseInt(time[1]) + (time[3] ? 12 : 0));
      d.setMinutes(parseInt(time[2]) || 0);
    }
    return d;
  }
  const handleSubmitCodigo = useCallback(async (dataForm: FindCodigoFormData) => {
    try {
      setLoading(true);
      const { data } = await api.get("/users/code", {
        params: {
          code: dataForm.codigo,
          birth: dataForm.nascimento,
          idTenant: tenant.idTenant,
        }
      });

      console.log(data);
      switch (data.cadastro) {
        case 0:
          addToast({
            type: "error",
            title: 'Não encontrado',
            description: 'Não encontramos nenhum cadastro com essas informações',
          });
          break;
        case 1:
        case 2:
          setUserTemp(data);
          if (aulaSelected) {
            const register: IVerify = {
              idAula: aulaSelected.IdAula,
              horaInicio: aulaSelected.HorarioInicio,
              idAulaGrade: aulaSelected.IdGrade,
              data: getDataSemana(aulaSelected.DiaSemana),
              idPosicao: posicao?.Id,
            }
            try {
              const response = await api.post('/checkin/confirm_no_token/' + tenant.idTenant + '/' + data.id + '/' + data.tipoPessoa, register);
              const { Retorno, Mensagem } = response.data;
              if (Retorno == 19) {
                // addToast({
                //   type: "info",
                //   title: "Aula lotada",
                //   description: "Colocamos você na fila de espera. Assim que surgir uma vaga você irá receber um aviso no aplicativo"
                // });
                setTextSuccessModal("Agora entrou fila de espera na aula de " + aulaSelected?.NomeAula + ".")
                setTabCode(2);
                setIsStoppedSuccess(false);
                setStartCountClose(true);
                getClasses();
              } else
                if (Retorno == 2) {
                  setTextSuccessModal("Registrado(a) com sucesso na aula de " + aulaSelected?.NomeAula)
                  setTabCode(2);
                  setIsStoppedSuccess(false);
                  setStartCountClose(true);
                  // addToast({
                  //   type: "success",
                  //   title: "Presença registrada com sucesso",
                  // });
                  getClasses();
                } else {
                  addToast({
                    type: "error",
                    title: Mensagem,
                  });
                }
            } catch (error) {
              //  console.log(error)
              let mess = "Não foi possível registrar a presença";
              if (error.response.data) {
                mess = error.response.data
              }

              addToast({
                type: "error",
                title: mess,
              });
            }

            // setSegundoPasso(SegundoPasso.loginSenha);
            // setTab(1);
          }

          break;
      }


    } catch (err) {
      console.log(err)
      return;
    } finally {
      setLoading(false);
    }
  }, [aulaSelected, tenant, posicao]);

  function getDataSemana(diaSemana: number): string {
    const dateAula = new Date();
    var dateAulaSemana = setDay(dateAula, diaSemana - 1)

    if (isBefore(new Date, dateAulaSemana)) {
      dateAulaSemana = addDays(dateAulaSemana, 7);
    }
    return format(dateAulaSemana, 'yyyy-MM-dd');
  }

  const handleSubmitFindCPF = useCallback(async (dataForm: FindCPFFormData) => {
    try {
      setLoading(true);
      //Buscar por CPF na API
      const { data } = await api.get("/users/cpf", {
        params: {
          cpf: dataForm.cpf,
          idTenant: tenant.idTenant,
        }
      });
      switch (data.cadastro) {

        case 1:
          //Criar senha
          setUserTemp(data);
          setSegundoPasso(SegundoPasso.cadastroSenha);
          if (emailSignupRef.current) {
            emailSignupRef.current.value = data.email;
          }
          setTab(1);
          setTimeout(() => {
            passwordRef.current?.focus();
            setLarguraModal("md")
          }, 800)
          break;
        case 2:
          setUserTemp(data);
          setSegundoPasso(SegundoPasso.loginSenha);
          setTab(1);
          setTimeout(() => {
            passwordRef.current?.focus();
            setLarguraModal("md")
          }, 800);
          break;
      }
      console.log(data);

    } catch (err) {
      console.log(err)
      addToast({
        type: "error",
        title: 'CPF Inválido',
        description: 'CPF parece inválido ou não localizado',
        time: 10000
      });
      return;
    } finally {
      setLoading(false);
    }
  }, [])

  const handleSubmitSigIn = useCallback(async (data: SignInFormData) => {
    try {
      //console.log(userTemp)
      setLoading(true);

      if (!userTemp) {
        throw new Error("Cadastro não encontrado")
      }
      if (aulaSelected) {
        sessionStorage.setItem('@HubfitTotem:class', JSON.stringify(aulaSelected));
        sessionStorage.setItem('@HubfitTotem:action', "class_register");
      }
      await signIn({
        email: userTemp.email,
        password: data.password,
      });
      setLoadClass(false);
      if (aulaSelected) {
        history.push('/classes/list')
      } else {
        history.push('/dashboard')
      }

    } catch (err) {
      console.log(err)
      setLoading(false);

      addToast({
        type: "error",
        title: 'Erro na autenticação',
        description: 'Erro no login, verifique suas credenciais.',
      });

    } finally {
      setLoading(false);
    }
  }, [history, signIn, addToast, userTemp])

  const onSubmitSignupPassword = useCallback((data: SignUpFormData) => {
    try {

      setLoading(true);
      if (userTemp) {
        const formCreate: IFormCreateLogin = {
          senha: data.password,
          idTenant: tenant.idTenant,
          email: data.email,
          tipoPessoa: userTemp.tipoPessoa,
          id: userTemp.id,
        }



        api.post('/login', formCreate).then((response) => {
          const usuario = response.data;

          if (aulaSelected) {
            sessionStorage.setItem('@HubfitTotem:class', JSON.stringify(aulaSelected));
            sessionStorage.setItem('@HubfitTotem:action', "class_register");
          }
          updateUser(response.data, usuario.token);
          setLoadClass(false);
          if (aulaSelected) {
            history.push('/classes/list')
          } else {
            history.push('/dashboard')
          }
          setLoading(false);

        }).catch((error) => {
          setLoading(false);
          let mess = "Não foi possível criar sua conta";
          if (error.response.data) {
            mess = error.response.data
          }
          addToast({
            type: "error",
            title: 'Ops...',
            description: mess,
          });
        });

      }
    } catch (err) {
      console.log(err)
    } finally {

    }
  }, [userTemp, tenant]);

  const handleEnter = useCallback((event) => {
    if (event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  }, []);

  const timerProps = {
    isPlaying: true,
    size: 40,
    strokeWidth: 3
  };

  const filterReservadoList = useCallback((idNode) => {
    if (verificacao) {
      return verificacao?.Mapa.filter(d => d.Id == idNode && d.MinhaPosicao == false && (d.Reservada == true || d.NaoRegistraPresenca == true)).length > 0;
    } else {
      return false;
    }
  }, [verificacao]);

  const clickVaga = useCallback((pos: MapaNode) => {
    console.log(pos);
    console.log(verificacao);
    if (verificacao && verificacao.Mapa) {

      if (pos.NaoRegistraPresenca == true) {
        addToast({
          type: "error",
          title: 'Posição não disponível',
          description: 'Tente selecionar outra posição.',
        });
      } else
        if (pos.Reservada == false) {
          setPosicao(pos);
          setTitleModal("Digite seu código e nascimento")

          setTabCode(1);
          setTimeout(() => {
            codigoRef.current?.focus();
          }, 800)
        } else {
          addToast({
            type: "error",
            title: 'Posição já reservada',
            description: 'Tente selcionar outra posição.',
          });
        }

    }
  }, [verificacao])

  const onChangeCPF = useCallback((input) => {
    setInputCPF(input);
    formCpf.setValue("cpf",input);
  }, []);
  const onChangeSenha = useCallback((input) => {
    setInputSenha(input);
    formPassword.setValue("password",input);
  }, []);
  const onChangeCriarSenha = useCallback((input) => {
    setInputCriarSenha(input);
    formPasswordSignUp.setValue("password",input);
  }, []);
  return (
    <div className={classes.mainRaised}>
      <div>
        <Toolbar id="back-to-top-anchor" className={classes.backToTopAnchor} />
        <Content>
          <Grid container spacing={3}
            direction="row"
            justify="center"
            alignItems="center">
            <Grid item xs={12} sm={10} md={10} lg={8}>
              <Grid container
                direction="row"
                justify="center"
                alignItems="center">
                <Grid item xs={12} sm={12} md={10} lg={8}>
                  <div >


                    <Grid spacing={3} container
                      direction="row"
                      justify="center"
                      alignItems="center">
                      <Grid item xs={12} className={classes.containerLogo}>
                        <img src={tenant.url_Logo} alt={tenant.name} className={classes.imgLogo} />
                      </Grid>
                      {(tenant.permissions.newSales || tenant.permissions.renewSales) &&
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          <Button fullWidth onClick={handleClickBuy} variant="contained" size="large" color="primary" className={classes.margin}>
                            <Create />
                            {tenant.text_new}
                          </Button>
                        </Grid>
                      }
                      {(tenant.permissions.newSales || tenant.permissions.renewSales || tenant.permissions.appointments || tenant.permissions.payments || tenant.permissions.training) &&
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          <Button fullWidth onClick={handleClickClient} variant="contained" size="large" color="primary" className={classes.margin}>
                            <AssignmentInd />
                            {tenant.text_portal}
                          </Button>
                        </Grid>
                      }
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={3}
            direction="row"
            justify="center"
            alignItems="center">

            {(!listAulas || listAulas.length > 0) && !loadingClasses &&
              <Grid item xs={12} sm={12} md={10} lg={8}>
                <Typography variant="h3" className={classes.titleClasses}>Aulas em andamento</Typography>
                <div>
                  <div>
                    <Grid container spacing={3} direction="row"
                      justify="center"
                      alignItems="center" >

                      {listAulas?.map(aula => (
                        <Grid item xs={12} sm={12} md={12} lg={12} key={aula.IdGrade}>
                          <Card className={classes.root} onClick={() => handleClickAula(aula.IdGrade)}>
                            <Hidden xsDown>
                              <CardMedia
                                className={classes.cover}
                                image={aula.FotoAula}
                                title={aula.NomeAula}
                              />
                            </Hidden>
                            <div className={classes.details}>

                              <CardContent className={classes.content}>
                                <Typography variant="h3" className={classes.titleClass}>{aula.NomeAula}</Typography>

                                <div className={classes.divFlexTime}>
                                  <div className={classes.flexOne}>
                                    <span className={classes.timeClass}> <Chip className={classes.vagas} label={aula.HorarioInicio.replace(':00', '') + 'h'} /></span>

                                    <Hidden xsDown>
                                      <Timer className={classes.timeRest} start={parseTime(aula.HorarioInicio)} />
                                    </Hidden>
                                  </div>
                                  <div className={classes.divVagas}>
                                    {aula.PresencaConfirmada &&
                                      <Chip color="primary" className={classes.textConfirmado} label="Confirmado" />
                                    }
                                    {!aula.PresencaConfirmada &&
                                      <>
                                        <Typography variant="body2" className={classes.textVagas}>Vagas</Typography>
                                        <Chip color="primary" className={classes.vagas} label={`${aula.Presencas.length} / ${aula.Capacidade}`} />
                                      </>
                                    }
                                  </div>
                                </div>


                              </CardContent>


                            </div>
                          </Card>
                        </Grid>
                      )
                      )}

                    </Grid>

                  </div>
                </div>
              </Grid>
            }
          </Grid>
        </Content>
      </div>
      <ScrollTop >
        <Fab color="primary" size="small" aria-label="Voltar ao topo">
          <KeyboardArrowUp />
        </Fab>
      </ScrollTop>
      <Dialog maxWidth={larguraModal} scroll={'body'} fullWidth onClose={handleCloseModal} aria-describedby="form-dialog-title" open={openModal}>
        <DialogTitle id="confirmation-dialog-title">{titleModal}
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers >
          {!openModalLogin && !openModalLoginCode &&
            <div className={classes.section2}>
              <Grid spacing={1} direction="column"
                justify="center"
                alignItems="center" >
                {tenant.localApiStatus && tenant.fingerprintReader &&

                  <>
                    <Grid item xs={12}  >
                      <Lottie options={defaultOptions}
                        height={200}
                        width={200}
                        speed={0.6}
                      />
                      {/* <Button fullWidth className={classes.btnItemLoginComponent} variant="contained" size="large" color="primary" onClick={handleClickSignInFingerprint} >
    <div className={classes.btnItemLogin}>
      <Fingerprint fontSize="large" />
  Impressão Digital
  </div>
  </Button> */}
                    </Grid>

                  </>
                }
                {(tenant.auth_cpf || tenant.auth_qrcode) && tenant.localApiStatus && tenant.fingerprintReader &&
                  <>
                    <Divider variant="middle" className={classes.dividerLine} />
                    <Typography
                      className={classes.dividerInset}
                      color="textSecondary"
                      display="block"
                      variant="caption"
                      align="center"
                    >
                      Outras opções
                    </Typography>
                  </>
                }
                {tenant.auth_cpf &&
                  <Grid item xs={12}   >

                    <Button fullWidth className={classes.btnItemLoginComponent} variant="contained" size="large" color="primary" onClick={handleClickSignInCPF} >
                      <div className={classes.btnItemLogin}>
                        <AccountBox fontSize="large" />
                        CPF
                      </div>
                    </Button>
                  </Grid>
                }
                {tenant.auth_qrcode &&
                  <Grid item xs={12}  >
                    <Button fullWidth className={classes.btnItemLoginComponent} variant="contained" size="large" color="primary" onClick={handleClickSignInQRCode} >
                      <div className={classes.btnItemLogin}>
                        <CropFree fontSize="large" />
                        QRCODE
                      </div>
                    </Button>
                  </Grid>

                }
                {tenant.auth_code && aulaSelected &&
                  <Grid item xs={12}  >
                    <Button fullWidth className={classes.btnItemLoginComponent} variant="contained" size="large" color="primary" onClick={handleClickSignInCode} >
                      <div className={classes.btnItemLogin}>
                        <FormatListNumbered fontSize="large" />
                        MATRÍCULA
                      </div>
                    </Button>
                  </Grid>

                }
              </Grid>
            </div>
          }
          {openModalLoginCode &&
            <CarouselProvider

              className={openModalLoginCode ? undefined : classes.carrouselInvisible}
              disableKeyboard
              currentSlide={tabCode}
              touchEnabled={false}
              dragEnabled={false}
              isIntrinsicHeight={true}
              naturalSlideWidth={300}
              naturalSlideHeight={200}
              totalSlides={3}
            >
              <Slider >
                <Slide index={0}>
                  <Grid container
                    direction="column"
                    justify="center"
                    alignItems="center">
                    <Grid item xs={12} >
                      <div className={"resize-div"}  >
                        <div className={"resize-container"} >
                          {verificacao && verificacao.PossuiMapa == true && verificacao.Mapa &&
                            <div>
                              {verificacao && verificacao?.Mapa.map(node => (
                                <div key={node.Id} className={"resize-drag round-button " + (filterReservadoList(node.Id) ? "reservado" : "") + " " + (posicao?.Id == node.Id ? "selecionado" : "")}
                                  onClick={() => clickVaga(node)} data-id={node.Id} data-x={node.PositionX} data-y={node.PositionY}>
                                  <span className={"round-button-circle"}>{node.Label}</span>
                                </div>
                              ))}
                            </div>
                          }
                        </div>
                      </div>
                    </Grid>

                  </Grid>
                </Slide>
                <Slide index={1} >

                  <CardBody>
                    {posicao &&
                      <Grid container style={{ marginBottom: 8 }}
                        direction="column"
                        justify="center"
                        alignItems="center"
                      >
                        <Grid item xs={12} alignItems='center'>Posição</Grid>
                        <Grid item xs={12} alignItems='center'>
                          <div className={"resize-drag round-button"} style={{
                            position: 'relative',
                            width: 55,
                            height: 55,
                            fontSize: 25
                          }}>
                            <span className={"round-button-circle"}>{posicao.Label}</span>
                          </div>
                        </Grid>
                      </Grid>
                    }
                    <form onSubmit={formCodigo.handleSubmit(handleSubmitCodigo)}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} >
                          <FormControl fullWidth error={!!formCodigo.errors.codigo?.message}>
                            <InputLabel htmlFor="codigo">Código de matrícula</InputLabel>
                             <InputMask
                              mask="p999999"
                              disabled={false}
                              maskChar=""
                              formatChars={{ "9": "[0-9]", "p": "[pP\0-9]", "?": "[0-9 ]" }}
                            > 
                            {() => <Input style={{ fontSize: 24 }} onKeyDown={handleEnter} name="codigo" id="codigo" type="tel" inputRef={(e) => {
                              formCodigo.register(e);
                              codigoRef.current = e;

                            }}
                            />}
                             </InputMask> 
                            <FormHelperText>{formCodigo.errors.codigo?.message}</FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} >
                          <FormControl fullWidth error={!!formCodigo.errors.nascimento?.message}>
                            <InputLabel htmlFor="nascimento">Data de nascimento</InputLabel>
                           <InputMask
                              mask="99/99/9999"
                              disabled={false}
                              maskChar=""
                            > 
                            {() => <Input style={{ fontSize: 24 }} name="nascimento" id="nascimento" type="tel" inputRef={(e) => {
                              formCodigo.register(e);
                              nascimentoRef.current = e;
                            }}
                            />}
                             </InputMask> 
                            <FormHelperText>{formCodigo.errors.nascimento?.message}</FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <div className={classes.wrapper}>
                        <Button disabled={loading} type="submit" fullWidth variant="contained" color="primary" size="large">
                          Registrar presença
                        </Button>
                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                      </div>
                    </form>
                    <Grid container
                      direction="row"
                      justify="space-between"
                      alignItems="flex-end"
                      style={{ marginTop: 20 }}
                    >
                      <Grid item xs={6} alignItems='flex-start' >
                        {posicao &&
                          <Button onClick={handleTabReturn}>
                            <ArrowLeft />
                            Voltar
                          </Button>
                        }
                      </Grid>
                      <Grid item xs={6} style={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}>
                        {/* <CountdownCircleTimer
                          {...timerProps}
                          isPlaying
                          duration={40}
                          colors={['#56c972', '#F7B801', '#A30000', '#A30000']}
                          colorsTime={[10, 7, 4, 0]}
                          onComplete={handleCloseModal}
                        >
                          {({ remainingTime }: any) => (
                            <span style={{ color: theme.palette.grey[500] }}>
                              {remainingTime}
                            </span>
                          )}

                        </CountdownCircleTimer> */}
                      </Grid>
                    </Grid>
                  </CardBody>

                </Slide>
                <Slide index={2}>
                  <Grid container
                    direction="column"
                    justify="center"
                    alignItems="center">
                    <Grid item xs={12}>
                      <div className={classes.profile}>
                        <div className={classes.avatarDiv}>
                          <Badge
                            overlap="circle"
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                            badgeContent={

                              <Lottie options={defaultOptionsSuccess}
                                height={48}
                                width={48}
                                speed={0.6}
                                isStopped={isStoppedSuccess}
                              />

                            }
                          >

                            <Avatar alt={userTemp?.nome} src={userTemp?.avatarUrl} className={classNames(classes.large, classes.avatar)} />
                          </Badge>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12} >
                      <Typography gutterBottom variant="h4" style={{ marginTop: 20 }} >
                        {userTemp?.nome}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} >
                      <Typography gutterBottom variant="body2" style={{ marginTop: 20 }} >
                        {/* {userTemp?.nome} */}
                        {textSuccessModal}

                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 20 }}>
                      <Button type="button" fullWidth variant="outlined" color="primary" size="large" onClick={handleCloseModal}>
                        Fechar
                      </Button>
                    </Grid>
                    <Grid container spacing={3}
                      direction="column"
                      justify="center"
                      alignItems="flex-end"
                      style={{ marginTop: 20 }}
                    >

                      <Grid item xs={12} alignItems='center'>
                        {/* <CountdownCircleTimer

                          {...timerProps}
                          isPlaying={startCountClose}
                          duration={8}
                          colors={['#56c972', '#F7B801', '#A30000', '#A30000']}
                          colorsTime={[10, 7, 4, 0]}
                          onComplete={handleCloseModal}
                        >
                          {({ remainingTime }: any) => (
                            <span style={{ color: theme.palette.grey[500] }}>
                              {remainingTime}
                            </span>
                          )}

                        </CountdownCircleTimer> */}
                      </Grid>
                    </Grid>
                  </Grid>
                </Slide>
              </Slider>
            </CarouselProvider>
          }
          {openModalLogin &&
            <CarouselProvider

              className={openModalLogin ? undefined : classes.carrouselInvisible}
              disableKeyboard
              currentSlide={tab}
              touchEnabled={false}
              dragEnabled={false}
              isIntrinsicHeight={true}
              naturalSlideWidth={300}
              naturalSlideHeight={200}
              totalSlides={3}
            >
              <Slider >
                <Slide index={0} >
                  <CardBody>
                    <CardTextHeader>
                      <h2>Digite seu CPF</h2>
                    </CardTextHeader>
                    <form onSubmit={formCpf.handleSubmit(handleSubmitFindCPF)}>
                      <FormControl
                        fullWidth error={!!formCpf.errors.cpf?.message}>
                        <InputLabel htmlFor="cpf"  >CPF</InputLabel>



                        <Input onFocus={() => {
                          setKeyboardVisibilityCPF(true);
                        }} value={inputCPF} onChange={(e) => onChangeCPF(e.target.value)} name="cpf" id="cpf" type="tel" inputRef={(e) => {
                          formCpf.register(e);
                          cpfRef.current = e;
                        }}
                        />
                        <FormHelperText>{formCpf.errors.cpf?.message}</FormHelperText>
                      </FormControl>
                      {keyboardVisibilityCPF && tenant.virtual_key && (
                        <Keyboard
                          keyboardRef={r => (keyboardCPF.current = r)}
                          layoutName={layout}
                          layout={customLayout}
                          theme={"hg-theme-default myTheme1"}
                          onChange={setInputCPF}
                          mergeDisplay={true}
                          maxLength={11}
                          display={{
                            '{bksp}': '&#8592',
                          }}
                        />
                      )}
                      <div className={classes.wrapper}>
                        <Button disabled={loading} type="submit" fullWidth variant="contained" color="primary" size="large">
                          Buscar
                        </Button>
                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                      </div>
                    </form>
                  </CardBody>

                </Slide>
                <Slide innerClassName={classes.RingOff} index={1} >
                  {segundoPasso == SegundoPasso.loginSenha &&
                    <CardBody>
                      <CardTextHeader>
                        <h2>Digite sua senha</h2>
                        <span className={classes.SpanCard}>
                          <HelpOutline fontSize="small" className={classes.IconInfo} /> Mesma senha do App Hubfit Aluno
                        </span>
                      </CardTextHeader>
                      <form onSubmit={formPassword.handleSubmit(handleSubmitSigIn)}>
                        <FormControl fullWidth error={!!formPassword.errors.password?.message}>
                          <InputLabel htmlFor="password">Senha</InputLabel>
                          <Input onFocus={() => {
                          setKeyboardVisibilitySenha(true);
                        }} value={inputSenha} onChange={(e) => onChangeSenha(e.target.value)} autoComplete="off" name="password" id="password" type="password" inputRef={(e) => {
                            formPassword.register(e);
                            passwordRef.current = e;
                          }}
                          />
                          <FormHelperText>{formPassword.errors.password?.message}</FormHelperText>
                        </FormControl>
                        {keyboardVisibilitySenha && tenant.virtual_key && (
                        <Keyboard
                          keyboardRef={r => (keyboardSenha.current = r)}
                          layoutName={layout}
                          theme={"hg-theme-default myTheme1"}
                          onChange={setInputSenha}
                          mergeDisplay={true}
                          display={{
                            '{bksp}': '&#8592',
                          }}
                        />
                      )}
                        <div className={classes.wrapper}>
                          <Button disabled={loading} type="submit" fullWidth variant="contained" color="primary" size="large">
                            Entrar
                          </Button>
                          {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                      </form>
                    </CardBody>
                  }
                  {segundoPasso == SegundoPasso.cadastroSenha &&

                    <CardBody>
                      <div className={classes.rootAvatar}>
                        <Avatar alt={userTemp?.nome} src={userTemp?.avatarUrl} className={classes.avatarLarge} />
                        <Typography variant="body2" component="p">Olá, {userTemp?.nome}</Typography>
                      </div>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <FormControl fullWidth error={!!formPasswordSignUp.errors.email?.message}>
                            <InputLabel htmlFor="email">E-mail</InputLabel>
                            <Input name="email" id="email" type="email" autoComplete="off"
                              inputRef={(e) => {
                                formPasswordSignUp.register(e);
                                emailSignupRef.current = e;
                              }}
                              endAdornment={
                                <InputAdornment position="end">
                                  <Email />
                                </InputAdornment>
                              } />
                            <FormHelperText>{formPasswordSignUp.errors.email?.message}</FormHelperText>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <form onSubmit={formPasswordSignUp.handleSubmit(onSubmitSignupPassword)}>
                        <CardTextHeader>
                          <h2>Crie uma senha</h2>
                          <span className={classes.SpanCard}>
                            <HelpOutline fontSize="small" className={classes.IconInfo} /> Mínimo 6 dígitos
                          </span>
                        </CardTextHeader>
                        <Grid container spacing={3}>


                          <Grid item xs={12} >
                            <FormControl fullWidth error={!!formPasswordSignUp.errors.password?.message}>
                              <InputLabel htmlFor="password">Nova senha</InputLabel>
                              <Input onFocus={() => {
                          setKeyboardVisibilityCriarSenha(true);
                        }} value={inputCriarSenha} onChange={(e) => onChangeCriarSenha(e.target.value)}  name="password" id="password" type="password"
                                inputRef={(e) => {
                                  formPasswordSignUp.register(e); passwordRef.current = e
                                }} autoComplete="off" />
                              <FormHelperText>{formPasswordSignUp.errors.password?.message}</FormHelperText>
                            </FormControl>
                          </Grid>
{/* 
                          <Grid item xs={12}>

                            <FormControl fullWidth error={!!formPasswordSignUp.errors.password_confirmation?.message}>
                              <InputLabel htmlFor="password_confirmation">Confirmar senha</InputLabel>
                              <Input onFocus={() => {
                          setKeyboardVisibilitySenha(true);
                        }} value={inputSenha} onChange={(e) => onChangeSenha(e.target.value)}  name="password_confirmation" id="password_confirmation" type="password" inputRef={formPasswordSignUp.register} autoComplete="off" />
                              <FormHelperText>{formPasswordSignUp.errors.password_confirmation?.message}</FormHelperText>
                            </FormControl>
                          </Grid> */}
                          {keyboardVisibilityCriarSenha && tenant.virtual_key && (
                        <Keyboard
                          keyboardRef={r => (keyboardCriarSenha.current = r)}
                          layoutName={layout}
                          theme={"hg-theme-default myTheme1"}
                          onChange={setInputCriarSenha}
                          mergeDisplay={true}
                          display={{
                            '{bksp}': '&#8592',
                          }}
                        />
                      )}
                        </Grid>
                        <div className={classes.wrapper}>
                          <Button disabled={loading} type="submit" fullWidth variant="contained" color="primary" size="large">
                            Salvar
                          </Button>
                          {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                      </form>
                    </CardBody>
                  }

                </Slide>
              </Slider>

            </CarouselProvider>
          }
        </DialogContent>
      </Dialog>
      <DialogQrCode open={openModalQrCode} onClose={handleCloseModalQRCode} aula={aulaSelected} />
      <DialogBiometric open={openModalBiometric} onClose={handleCloseModalFingerprint} aula={aulaSelected} isSearching={openModalBiometric} />
      <Backdrop className={classes.backdrop} open={loadingDigital} >
        <CircularProgress color="inherit" />
      </Backdrop>


    </div >

  )
};
export default Home;
