import styled, {keyframes} from 'styled-components';
import {shade} from 'polished';

export const ContentModal = styled.div`
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: 800px;

display: flex;
`;


export const Content = styled.div`

   padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;

   /* @media (min-width: 576px){
    max-width: 800px
  }
  @media (min-width: 600px){
    max-width: 550px
  }
  @media (min-width: 802px){
    max-width: 595px
  } */
  /* @media (min-width: 576px){
    max-width: 800px
  } */
  /* @media (min-width: 1200px){
    max-width: 1140px;
  } */
    z-index: 2;
    position: relative;
    padding-top: 1vh;
    color: #FFFFFF;
    padding-bottom: 200px;
`;

const appearFromLeft = keyframes`
    from{
        opacity: 0;
        transform: translateX(-50px);

    }
    to{
        opacity: 1;
        transform: translateX(0);
    }
`;

export const CardTextHeader = styled.div`
  margin: 0px 0 20px;
  h2{
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;
export const FooterCard = styled.div`

`;
export const AnimationContainer = styled.div`

    /* display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */

    animation: ${appearFromLeft} 1s;

`;

export const Background = styled.div`
    min-height: 100vh;
    height: auto;
    display: inherit;
    position: relative;
    margin: 0;
    padding: 0;
    border: 0;
    align-items: center;
    &:before {
      background: rgba(0, 0, 0, 0.5);
    };
    &:before, &:after {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      display: block;
      left: 0;
      top: 0;
      content: '';
    };
    & footer li a,& footer li a:hover,& footer li a:active{
      color: #FFFFFF;
    };
    & footer{
      position: absolute;
      bottom: 0;
      width: 100%;
    };
`;
