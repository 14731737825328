import styled, {keyframes} from 'styled-components';
import {shade} from 'polished';


export const Content = styled.div`

   /* padding-right: 15px;
  padding-left: 15px; */
  margin-right: auto;
  margin-left: auto;
  width: 100%;

  h1, h2, h3, h4, h5, h6 {
      flex-grow: 1;
      margin: 1.75rem 0 0.875rem;
      text-decoration: none;
      font-weight: 700;
    }

  @media (min-width: 1200px){
    /* max-width: 1140px; */
  }
    z-index: 2;
    position: relative;
    padding-top: 2vh;
    padding-bottom: 200px;
`;


