import React from "react";
import{CardFooterDivBody} from './styles';



const CardFooter: React.FC = ({children,  ...rest }) => {

  return (
    <CardFooterDivBody {...rest}>
      {children}
    </CardFooterDivBody>
  );
}

export default CardFooter;
