import React, { useCallback } from 'react';

import { AppBar,Toolbar,IconButton,Button, Theme, makeStyles,createStyles,AppBarProps, Avatar, Hidden } from '@material-ui/core';
import { ArrowBack,PowerSettingsNew, QueryBuilder  } from '@material-ui/icons';
import { useAuth } from '../../hooks/auth';
import { useHistory } from 'react-router-dom';
import Clock from '../Clock';
import classNames from 'classnames';

type IAppBarPros =  AppBarProps & {
    linkPush?: string | undefined;
    isBack?: boolean;
    avatar: boolean;
    removeBack?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    right: {
      marginLeft: "auto",
    },
    divClock:{
      display: 'flex',
    alignItems: 'center',
    alignContent: 'inherit',
    '& p':{
      margin: '2px 0 0 3px',
    }
    },
    signOut:{
      '& span':{
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column-reverse',
      }
    },
    spanName:{
      marginRight: '5px',
    }
  })
);

const Header: React.FC<IAppBarPros>  = ({linkPush, isBack,avatar,removeBack, ...restProps}) => {
  const { user, signOut } = useAuth();
  const classes = useStyles();
  const history = useHistory();
  const handleClickReturn = useCallback(() => {
    if(isBack || !linkPush){
      history.goBack();
    }else{
      history.push(linkPush);
    }
  }, [history,linkPush,isBack]);
  const handleLogout = useCallback(() => {
    signOut();
    history.push('/');
  }, [signOut,history]);

  const handleClickAvatar = useCallback(() => {
    history.push('/dashboard');
  }, [history]);

    return (
      <AppBar position="static" {...restProps}>
        <Toolbar>
          {!removeBack &&
          <IconButton aria-label="Voltar" color="inherit" onClick={handleClickReturn}>
            <ArrowBack />
          </IconButton>
          }
           <Hidden xsDown>
            <div className={classes.divClock}>
          <QueryBuilder /> <Clock />
          </div>
          </Hidden>
          {user &&
          <div className={classes.right}>

          {avatar &&
          <>
            <span className={classes.spanName}>Olá {user.firstName}</span>
          <IconButton >

           <Avatar   alt={user.name} src={user.avatarURL} onClick={handleClickAvatar} />
           </IconButton>
           </>
          }
          <Button className={classes.signOut}  color="inherit" onClick={handleLogout}>
            Sair
        <PowerSettingsNew />
          </Button>
          </div>
        }
        </Toolbar>
      </AppBar>
    )
}
export default Header;
