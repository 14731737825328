import React, { useCallback, useState, useEffect } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { format, setDay, isBefore, addDays, parseISO } from 'date-fns';
import classNames from "classnames";

import { ContentModal } from './styles';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import {
  Typography, Button, Grid, Divider,
  DialogContent as MuiDialogContent, Dialog, CircularProgress, CardMedia, CardContent, Card, Chip, Paper, Avatar, AppBar, Tabs, Tab, Box, useTheme, List, ListItem, ListItemAvatar, ListItemText, CardHeader, IconButton, ListItemSecondaryAction, DialogTitle
} from "@material-ui/core";
import { AvatarGroup, Skeleton } from '@material-ui/lab';
import { Content } from "./styles";
import api from '../../services/api';
import ReactPlayer from 'react-player';

import { useToast } from '../../hooks/toast';
import Header from '../../components/Header';
import Timer from '../../components/TimerCountdown';
import { red, green } from '@material-ui/core/colors';
import { useAuth } from '../../hooks/auth';
import { IClassItem, IVerify, IReturnVerify } from '../../models/Classes/IClassesDTO';
import { ArrowLeft, PlayArrow, Print, Star, Close, SentimentDissatisfied } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { Treino, Exercicio } from '../../models/Training/TrainingDTO';
import { ptBR } from 'date-fns/locale';
import axios from 'axios';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      cursor: 'pointer',
    },
    rootLoading: {
      display: 'flex',
      cursor: 'default',
      opacity: '0.8',
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    details: {
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
    },
    vagas: {
      borderRadius: '23px',
      height: '48px',
      fontSize: '32px',

    },
    divVagas: {
      display: 'flex',
      alignItems: 'center',
      width: '138px',
      flexDirection: 'column',
      alignSelf: 'center',
      // marginTop: '-16px',
    },
    content: {
      flex: '1 0 auto',
    },
    cover: {
      width: 200,
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    playIcon: {
      height: 38,
      width: 38,
    },
    titleClass: {
      margin: '2px 5px 8px 14px !important',
    },
    timeClass: {
      margin: '0 !important',
      color: theme.palette.text.secondary,
    },
    timeRest: {
      color: theme.palette.text.disabled,
      marginLeft: '10px',
      fontSize: '14px',
    },
    divFlexTime: {
      display: 'inline-flex',
      alignItems: 'flex-end'

    },
    textVagas: {
      color: theme.palette.text.secondary,
    },
    textConfirmado: {
      background: green[500],
      height: '32px',
      fontSize: '19px',
      borderRadius: '23px',
    },
    coverModal: {
      width: 'auto',
      height: '185px',
      backgroundColor: '#e4e4e4',
    },
    titleLigh: {
      color: theme.palette.text.disabled,
    },
    titleCoverModal: {
      position: 'absolute',
      top: '25%',
      left: '50%',
      color: 'white',
      transform: 'translate(-50%, -50%)',
      textShadow: '1px 2px 4px #000000',
    },
    contentModal: {
      position: 'relative',
      height: '385px',
      paddingLeft: '0px',
      paddingRight: '0px',
    },
    btnRegister: {
      position: 'absolute',
      right: '15px',
      top: '-18px',
    },
    cardText: {
      textAlign: 'center',
    },
    contentDialog: {
      marginTop: '10px',
    },
    section1: {
      margin: '4px 16px 14px',
    },
    section2: {
      margin: theme.spacing(2),
    },
    buttonRed: {
      color: theme.palette.getContrastText(red[500]),
      background: red[500],
      '&:hover': {
        backgroundColor: red[700],
      },
    },
    disableRegister: {

      color: 'rgb(0 0 0 / 55%)',
      backgroundColor: 'rgb(189 189 189 / 84%)',
      '&:hover': {
        backgroundColor: 'rgb(189 189 189 / 84%)',
      },
      cursor: 'inherit',
    },
    noClasses: {
      textAlign: 'center',
      padding: '60px',
      opacity: '0.7',
      '& h5': {
        color: theme.palette.text.secondary,
      }
    },
    divNoPlay: {
      marginLeft: '56px',
    },
    paperTraining: {
      marginBottom: '17px',
    },
    buttonPrint: {
      marginTop: '0px',
      marginRight: '0px',
    },
    dataExerc: {
      margin: '0 2px'
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    GridList: {
      margin: '28px 0 !important',
    },
    iconSmile: {
      fontSize: '5.1875rem',
    }, btnBack: {
      margin: '12px 0',
    }
  }),
);





const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);


interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Training: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [training, setTraining] = useState<Treino | undefined>();
  const [tab, setTab] = useState(0);
  const [openModal, setOpenModal] = React.useState(false);
  const { tenant } = useAuth();

  const [loadingData, setLoadingData] = React.useState(true);
  const [noTraining, setNoTraining] = React.useState(false);
  const [idTrainingToday, setIdTrainingToday] = React.useState(0);
  const [exercicioPlayer, setExercicioPlayer] = React.useState<Exercicio>();
  const [printing, setPrinting] = React.useState(false);
  const { addToast } = useToast();
  const history = useHistory();

  useEffect(() => {
    setLoadingData(true);
    api.get('/training', {
      params: {
        idBranch: tenant.idBranch
      }
    }).then(response => {
      setLoadingData(false);
      if (!response.data) {
        setNoTraining(true);
      } else {
        setTraining(response.data);
        const dados = response.data as Treino;
        const showTraining = dados.series.filter(serie => {
          return serie.showFirst == true;
        })
        if (showTraining.length > 0) {
          setIdTrainingToday(showTraining[0].id)
          const indexTraining = dados.series.indexOf(showTraining[0]);
          setTab(indexTraining);
        }
      }
    }).catch(() => {
      addToast({
        type: "error",
        title: 'Erro de conexão',
        description: "Não foi possível se conectar ao servidor"
      });
    })
  }, [])

  function a11yProps(index: any) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  const handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };
  const handleChangeIndex = (index: number) => {
    setTab(index);
  };
  const handlePrintClick = useCallback(async () => {
    const idSerie = training?.series[tab].id;
    try {
      setPrinting(true);
      await axios.post('http://'+tenant.localApiUrl+":5001/api/training/print?idserie=" + idSerie, training);

      api.put('/training', null, {
        params: {
          idserie: idSerie,
          idbranch: tenant.idBranch
        }
      })
    } catch (err) {
      console.log(err)
      addToast({
        type: "error",
        title: 'Erro de impressão',
        description: "Não foi possível se conectar com a impressora"
      });
    } finally {
      setPrinting(false);
    }
  }, [tab, training, tenant.localApiUrl]);

  const handleClickExercise = useCallback((exercicio) => {
    setExercicioPlayer(exercicio);
    setOpenModal(true);
  }, [])
  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, [])
  const handleClickReturn = useCallback(() => {
    history.goBack();
  }, [history])
  return (
    <>
      <Header linkPush="/dashboard" avatar />
      <div>
        <div>
          <Content>
            <Grid container spacing={3} direction="row"
              justify="center"
              alignItems="center" >
              <Grid item xs={12} sm={12} md={10} lg={8} >
                {noTraining && !loadingData &&
                  <Paper className={classes.paperTraining}>
                    <Grid container direction="column" className={classes.GridList}
                      justify="center"
                      alignItems="center" >

                      <h4 >Nenhum treino ativo</h4>
                      <SentimentDissatisfied className={classes.iconSmile} />

                      <Button onClick={handleClickReturn} className={classes.btnBack}>
                        <ArrowLeft />
      Voltar
  </Button>
                    </Grid>
                  </Paper>
                }
                {loadingData &&
                  <Paper className={classes.paperTraining}>
                    <Grid container direction="column"
                      justify="center"
                      alignItems="center" >
                      <Grid item xs={12} className={classes.GridList}>
                        <CircularProgress size={40} />
                      </Grid>
                    </Grid>
                  </Paper>
                }
                {!loadingData && !noTraining &&
                  <>
                    <Paper className={classes.paperTraining}>
                      {training &&
                        <CardHeader

                          avatar={
                            <Avatar alt={training?.nameInstructor} src={training?.avatarInstructor} />
                          }
                          action={
                            <Button
                              disabled={printing || !tenant.printer || !tenant.localApiStatus}
                              variant="contained"
                              color="primary"
                              onClick={handlePrintClick}
                              startIcon={<Print />}
                            >
                              Imprimir
                          {printing &&
                                <CircularProgress size={24} className={classes.buttonProgress} />
                              }
                            </Button>
                          }
                          title={`Prof: ${training.nameInstructor}`}
                          subheader={<>  <Chip size="small" color="secondary" label={training.description} />  <Chip size="small" label={`criação: ${format(parseISO(training.start), 'dd/MM/yyyy', { locale: ptBR })}`} /> <Chip size="small" label={`vencimento: ${format(parseISO(training.valid), 'dd/MM/yyyy', { locale: ptBR })}`} /> <Chip size="small" label={`sessões: ${training.sessionsDone}/${training.sessions}`} /> </>}
                        />
                      }
                    </Paper>
                    <Paper>
                      <AppBar position="static" color="default">

                        <Tabs
                          value={tab}
                          onChange={handleChangeTab}
                          indicatorColor="primary"
                          textColor="primary"
                          variant="fullWidth"
                          aria-label="full width tabs example"
                        >
                          {training?.series.map(serie => (
                            <Tab key={serie.id} label={
                              serie.id == idTrainingToday ?
                                <>
                                  <Typography color="textPrimary" variant="body2">
                                    {serie.description}
                                  </Typography>
                                  <Typography color="textSecondary" variant="body2">
                                    Treino de hoje
                            </Typography>
                                </>
                                : <> {serie.description}</>
                            }  {...a11yProps(training?.series.indexOf(serie))} />
                          ))}
                        </Tabs>
                      </AppBar>

                      <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={tab}
                        onChangeIndex={handleChangeIndex}
                      >
                        {training?.series.map(serie => (
                          <TabPanel value={tab} index={training?.series.indexOf(serie)} dir={theme.direction}>
                            {serie.observation &&
                              <>
                                <div className={classes.section1}>
                                  <Typography color="textSecondary" variant="body2">
                                    {serie.observation}
                                  </Typography>
                                </div>
                                <Divider variant="middle" />
                              </>
                            }
                            <List component="nav">
                              {serie?.exercises.map(exercise => (
                                <>
                                  {!!exercise.url_video ?
                                    <ListItem button key={exercise.id} onClick={() => handleClickExercise(exercise)}>
                                      <ListItemAvatar>
                                        <Avatar>
                                          <PlayArrow />
                                        </Avatar>
                                      </ListItemAvatar>

                                      <ListItemText primary={exercise.nameExercise} secondary={exercise.observation} />
                                      <ListItemSecondaryAction>
                                        <>
                                          {exercise.series &&
                                            <Chip label={`Séries: ${exercise.series}`} className={classes.dataExerc} />
                                          }
                                          {exercise.repetitions &&
                                            <Chip label={`Repet: ${exercise.repetitions}`} className={classes.dataExerc} />
                                          }
                                          {exercise.load &&
                                            <Chip label={`Carga: ${exercise.load}`} className={classes.dataExerc} />
                                          }
                                        </>
                                      </ListItemSecondaryAction>
                                    </ListItem>
                                    :
                                    <ListItem key={exercise.id}>
                                      <div className={classes.divNoPlay}></div>
                                      <ListItemText primary={exercise.nameExercise} secondary={exercise.observation} />
                                      <ListItemSecondaryAction>
                                        <>
                                          {exercise.series &&
                                            <Chip label={`Séries: ${exercise.series}`} className={classes.dataExerc} />
                                          }
                                          {exercise.repetitions &&
                                            <Chip label={`Repet: ${exercise.repetitions}`} className={classes.dataExerc} />
                                          }
                                          {exercise.load &&
                                            <Chip label={`Carga: ${exercise.load}`} className={classes.dataExerc} />
                                          }
                                        </>
                                      </ListItemSecondaryAction>
                                    </ListItem>
                                  }
                                  <Divider variant="inset" component="li" />
                                </>
                              ))}

                            </List>
                          </TabPanel>
                        ))}
                      </SwipeableViews>
                    </Paper>
                  </>
                }
              </Grid>

            </Grid>
          </Content>
        </div>
      </div>


      <Dialog maxWidth="md" onClose={handleCloseModal} aria-describedby="form-dialog-title" open={openModal}>
        <DialogTitle id="form-dialog-title" >
          <Typography variant="h6">{exercicioPlayer?.nameExercise}</Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleCloseModal}>
            <Close />
          </IconButton>
        </DialogTitle>

        <ContentModal className={classes.contentModal}>
          <ReactPlayer loop={true} controls={false} url={exercicioPlayer?.url_video} config={{ youtube: { playerVars: { 'autoplay': 1 } } }} />
        </ContentModal>

      </Dialog>

    </>
  )

}



export default Training;
