import React from 'react';
import { Grid, Typography, makeStyles,Theme } from '@material-ui/core';
const useStyles = makeStyles((theme: Theme) => ({
  GridList:{
    margin: '50px 0',
    '& h4':{
      color: 'white'
    }
  }
}));
const Error404: React.FC = () => {
  const classes = useStyles();

return (
  <Grid container direction="column"
  justify="center"
  alignItems="center" >
    <Grid item xs={12} className={classes.GridList}>
       <Typography variant="h4">Página não encontrada</Typography>
    </Grid>
  </Grid>

)

};
export default Error404;
