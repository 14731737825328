import styled, {keyframes} from 'styled-components';
import {shade} from 'polished';

export const Container = styled.div`
    height: 100vh;
    display: flex;
    align-items: stretch;
`;
export const Content = styled.div`
    display:flex;
    padding:20px;
    flex-grow: 1;
    width: 100%;
    flex:1;
    flex-direction: column;
    form{

        h5{
            margin-bottom: 10px;
            margin-top: 10px
        }



        a{
            color: #f4ede8;
            display: block;
            margin-top: 24px;
            text-decoration: none;
            transition: color 0.2s;
            &:hover{
                color: ${shade(0.2,'#f4ede8')};
            }
        }
    }
`;

const appearFromRight = keyframes`
 from{
        opacity: 0;
        transform: translateX(-50px);

    }
    to{
        opacity: 1;
        transform: translateX(0);
    }

`;
export const ContentInfo = styled.div`
  display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 500px;
    justify-content: start;
    padding: 48px;

    Button{
      color:#f5f5f5;
      margin: 30px 0;

    }

`;

export const AnimationContainer = styled.div`

    display:flex;
    flex:1;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    animation: ${appearFromRight} 1s;


    > a{
        color: #ff9000;
        display: block;
        margin-top: 24px;
        text-decoration: none;
        transition: color 0.2s;
        display: flex;
        align-items: center;
        svg{
            margin-right: 16px;
        }
        &:hover{
            color: ${shade(0.2,'#ff9000')};
        }
    }
`;
export const Background = styled.div`
    flex:1;
    background: #f5f5f5;
    width:100%;
`;
