import React, { useRef, useCallback, useEffect } from "react";
import {
  Input,
  Button,
  CircularProgress,
  Grid,
  Dialog,
  Typography,
  IconButton,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  InputLabel,
  FormHelperText,
  FormControl,
  Avatar,
} from "@material-ui/core";
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
  WithStyles,
  withStyles,
} from "@material-ui/core/styles";
import InputMask from "react-input-mask";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";
import { CarouselProvider, Slider, Slide } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import "./style.css";

import axios from 'axios';
import { green, blue } from "@material-ui/core/colors";
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';

import logoImg from '../../../assets/logo.svg';

import getValidationErrors from '../../../utils/getValidationErrors';
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Card from "../../../components/Card/Card";
import CardBody from "../../../components/Card/CardBody";
import CardFooter from "../../../components/Card/CardFooter";
import CardHeader from "../../../components/Card/CardHeader";

import {
  AnimationContainer,
  Content,
  CardTextHeader,
  FooterCard,
  ContentModal,
} from "./styles";

// import Input from '../../components/Input';


import {
  AccountCircle,
  ArrowLeft,
  PersonAdd,
  Lock,
  HelpOutline,
  Close as CloseIcon,
  ArrowForwardIos,
  Email,
} from "@material-ui/icons";
import InputAdornment from "@material-ui/core/InputAdornment";
import api from "../../../services/api";
import { rgb } from "polished";
import { yupResolver } from "@hookform/resolvers";
import { User } from "../../../models/Tenant/UserDTO";
import Axios from "axios";

interface FindCPFFormData {
  cpf: string;
}

interface SignInFormData {
  password: string;
}
interface SignUpFormData {
  password: string;
  password_confirmation: string;
  email: string;
}
interface IUserLogin {
  avatarUrl: string;
  email: string;
  nome: string;
  id: number;
  tipoPessoa: number;
  cadastroProspect: boolean;
  cadastro: number;
}
enum TipoPessoa {
  Colaborador = 0,
  Aluno = 1,
  Personal = 2,
  Prospect = 3,
  Banco = 4,
  Filial = 5,
  Empresa = 6,
}
enum ChecarCadastroCPF {
  SemCadastro = 0,
  PossuiCadastro = 1,
  PossuiLogin = 2,
  PossuiFacebook = 3,
}
interface IFormCreateLogin {
  id: number;
  email: string;
  senha: string;
  tipoPessoa: number;
  idTenant: string;
}
let dataFormsCpf: FindCPFFormData;
let dataFormsCreateLogin: IFormCreateLogin;
const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

const useStyles = makeStyles((theme: Theme) => ({
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: "60px 0 0",
    width: "100%",
    position: "relative",
  },
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  SpanCard: {
    color: "rgba(0,0,0,0.5)",
    display: "flex",
    alignItems: "center",
  },
  IconInfo: {
    color: blue[500],
  },
  RingOff: {
    "outline-style": "inherit",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  titleSignup: {
    margin: "10px 0",
  },
  rootAvatar: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  avatarLarge: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
}));

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

export enum SegundoPasso {
  cadastroSenha,
  loginSenha,
}

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const SignIn: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { signIn, tenant, updateUser, updateTenant } = useAuth();
  const cpfRef = useRef<HTMLInputElement | null>(null);
  const passwordRef = useRef<HTMLInputElement | null>(null);

  const [loading, setLoading] = React.useState(false);
  const [tab, setTab] = React.useState(0);
  const [openModal, setOpenModal] = React.useState(false);
  const [userTemp, setUserTemp] = React.useState<IUserLogin>();
  const [segundoPasso, setSegundoPasso] = React.useState<SegundoPasso>(
    SegundoPasso.loginSenha
  );
  const [cpfLogin, setCpfLogin] = React.useState("");

  const { addToast } = useToast();
  const history = useHistory();

  const cpfSignupRef = useRef<HTMLInputElement | null>(null);
  const emailSignupRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setTimeout(() => {
      cpfRef.current?.focus();
    }, 1000);
  }, []);
  useEffect(() => {
    axios
      .get(`http://${  tenant.localApiUrl  }:5001/api/totem/status`)
      .then((response) => {
        tenant.localApiStatus = true;
        tenant.fingerprintReader = response.data.fingerprintReader;
        tenant.printer = response.data.printer;
        updateTenant(tenant);
      })
      .catch((err) => {
        tenant.localApiStatus = false;
        updateTenant(tenant);
      });
  }, [tenant.localApiUrl]);

  const handleClickReturn = useCallback(() => {
    if (tab > 0) {
      setTab(tab - 1);
    }
  }, [tab]);

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const RegisterSignInPasswordSchema = Yup.object().shape({
    password: Yup.string().required("Digite a senha"),
  });
  const RegisterSignUpPasswordSchema = Yup.object().shape({
    password: Yup.string().required("Senha obrigatória"),
    password_confirmation: Yup.string().oneOf(
      [Yup.ref("password"), undefined],
      "Senha diferente a primeira"
    ),
  });
  const RegisterSignInCPFSchema = Yup.object().shape({
    cpf: Yup.string().required("Digite o CPF"),
  });

  const formCpf = useForm<FindCPFFormData>({
    resolver: yupResolver(RegisterSignInCPFSchema),
  });
  const formPassword = useForm<SignInFormData>({
    resolver: yupResolver(RegisterSignInPasswordSchema),
  });
  const formPasswordSignUp = useForm<SignUpFormData>({
    resolver: yupResolver(RegisterSignUpPasswordSchema),
  });
  const formCpfSignUp = useForm<FindCPFFormData>({
    resolver: yupResolver(RegisterSignInCPFSchema),
  });

  const handleSignUpClick = useCallback(() => {
    setOpenModal(true);
    setTimeout(() => {
      cpfSignupRef.current?.focus();
    }, 800);
  }, []);

  const onSubmitSignupPassword = useCallback(
    (data: SignUpFormData) => {
      try {
        setLoading(true);
        if (userTemp) {
          const formCreate: IFormCreateLogin = {
            senha: data.password,
            idTenant: tenant.idTenant,
            email: data.email,
            tipoPessoa: userTemp.tipoPessoa,
            id: userTemp.id,
          };

          api
            .post("/login", formCreate)
            .then((response) => {
              const usuario = response.data;

              const userStorage: User = {
                avatarURL: usuario.avatar_url,
                name: usuario.name,
                email: usuario.email,
                id: usuario.id,
                identifier: usuario.identifier,
                idTenant: usuario.idTenant,
                firstName: usuario.firstName,
                idBranch: usuario.idBranch,
                type: usuario.type,
              };

              updateUser(userStorage, usuario.token);
              history.push("/dashboard");
              setLoading(false);
            })
            .catch((error) => {
              setLoading(false);
              console.log(error.response.data);
              addToast({
                type: "error",
                title: "Ops...",
                description: error.response.data,
              });
            });
        }
      } catch (err) {
        console.log(err);
      } finally {
      }
    },
    [userTemp, tenant]
  );

  const handleSubmitFindCPF = useCallback(
    async (dataForm: FindCPFFormData) => {
      try {
        setLoading(true);
        // Buscar por CPF na API
        const { data } = await api.get("/users/cpf", {
          params: {
            cpf: dataForm.cpf,
            idTenant: tenant.idTenant,
          },
        });
        console.log(data);
        if (data.cadastro == 2) {
          setUserTemp(data);
          setSegundoPasso(SegundoPasso.loginSenha);
          setTab(1);
          setTimeout(() => {
            passwordRef.current?.focus();
          }, 800);
        } else if (data.cadastro == 1) {
          setUserTemp(data);
          setSegundoPasso(SegundoPasso.cadastroSenha);
          if (emailSignupRef.current) {
            emailSignupRef.current.value = data.email;
          }
          setTab(1);
          setTimeout(() => {
            passwordRef.current?.focus();
          }, 800);
        } else if (data.cadastro == 0) {
          addToast({
            type: "info",
            title: "Novo cadastro",
            description:
              "Não encontramos nenhum cadastro com seu CPF. Insira seus dados para criar um novo cadastro ou fale com a recepção.",
          });
          history.push({
            pathname: "/signup",
            state: {
              cpf: dataForm.cpf,
            },
          });
        }
      } catch (err) {
        console.log(err);

        return;
      } finally {
        setLoading(false);
      }
    },
    [segundoPasso]
  );

  const handleSubmitSigIn = useCallback(
    async (data: SignInFormData) => {
      try {
        console.log(data);
        setLoading(true);

        if (!userTemp) {
          throw new Error("Cadastro não encontrado");
        }
        await signIn({
          email: userTemp.email,
          password: data.password,
        });

        history.push("/dashboard");
      } catch (err) {
        console.log(err);
        setLoading(false);

        addToast({
          type: "error",
          title: "Erro na autenticação",
          description: "Erro no login, verifique suas credenciais.",
        });
      } finally {
        setLoading(false);
      }
    },
    [history, signIn, addToast, userTemp]
  );

  const handleSubmitFindCPFSignUp = useCallback(
    async (dataForm: FindCPFFormData) => {
      try {
        setLoading(true);
        // Buscar por CPF na API
        const { data } = await api.get("/users/cpf", {
          params: {
            cpf: dataForm.cpf,
            idTenant: tenant.idTenant,
          },
        });
        switch (data.cadastro) {
          case 0:
            // Sem cadastro, direcionar para tela de cadastro.
            history.push({
              pathname: "/signup",
              state: {
                cpf: dataForm.cpf,
              },
            });
            break;
          case 1:
            // Possui cadastro, pedir para fazer login.
            addToast({
              type: "info",
              title: "Cadastro encontrado",
              description:
                "Você já possui uma conta criada, digite seu CPF e sua senha na tela de login",
              time: 20000,
            });
            break;
          case 2:
            // Possui cadastro, pedir para fazer login.
            addToast({
              type: "info",
              title: "Faça login",
              description:
                "Você já possui uma conta criada, digite sua senha para fazer login",
              time: 20000,
            });
            setUserTemp(data);
            setSegundoPasso(SegundoPasso.loginSenha);
            setOpenModal(false);
            setCpfLogin(dataForm.cpf);
            setTimeout(() => {
              passwordRef.current?.focus();
            }, 800);
            setTab(1);
            break;
        }
        console.log(data);
      } catch (err) {
        console.log(err);
        return;
      } finally {
        setLoading(false);
      }
    },
    []
  );

  return (
    <>
      <Content>
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={5}>
            <AnimationContainer>
              <Card>
                <CardHeader>
                  <img src={tenant.url_Logo} alt={tenant.name} />
                </CardHeader>
                <CarouselProvider
                  disableKeyboard
                  currentSlide={tab}
                  touchEnabled={false}
                  dragEnabled={false}
                  isIntrinsicHeight
                  naturalSlideWidth={300}
                  naturalSlideHeight={200}
                  totalSlides={3}
                >
                  <Slider>
                    <Slide index={0}>
                      <CardBody>
                        <CardTextHeader>
                          <h3>Já é aluno(a)?</h3>
                          <h2>Digite seu CPF para login</h2>
                        </CardTextHeader>
                        <form
                          onSubmit={formCpf.handleSubmit(handleSubmitFindCPF)}
                        >
                          <FormControl
                            fullWidth
                            error={!!formCpf.errors.cpf?.message}
                          >
                            <InputLabel htmlFor="cpf">CPF</InputLabel>
                            <InputMask
                              mask="999.999.999-99"
                              disabled={false}
                              maskChar=""
                            >
                              {() => (
                                <Input
                                  name="cpf"
                                  id="cpf"
                                  type="tel"
                                  inputRef={(e) => {
                                    formCpf.register(e);
                                    cpfRef.current = e;
                                  }}
                                />
                              )}
                            </InputMask>
                            <FormHelperText>
                              {formCpf.errors.cpf?.message}
                            </FormHelperText>
                          </FormControl>
                          <div className={classes.wrapper}>
                            <Button
                              disabled={loading}
                              type="submit"
                              fullWidth
                              variant="contained"
                              color="primary"
                              size="large"
                            >
                              Buscar
                            </Button>
                            {loading && (
                              <CircularProgress
                                size={24}
                                className={classes.buttonProgress}
                              />
                            )}
                          </div>
                        </form>
                      </CardBody>
                    </Slide>
                    <Slide innerClassName={classes.RingOff} index={1}>
                      {segundoPasso == SegundoPasso.loginSenha &&
                        <CardBody>
                          <CardTextHeader>
                            <h2>Digite sua senha</h2>
                            <span className={classes.SpanCard}>
                              <HelpOutline
                                fontSize="small"
                                className={classes.IconInfo}
                              />{" "}

{' '}
Mesma senha do App Hubfit Aluno
</span>
                          </CardTextHeader>
                          <form
                            onSubmit={formPassword.handleSubmit(
                              handleSubmitSigIn
                            )}
                          >
                            <FormControl
                              fullWidth
                              error={!!formPassword.errors.password?.message}
                            >
                              <InputLabel htmlFor="password">Senha</InputLabel>
                              <Input
autoComplete="off"
name="password"
id="password"
type="password"
inputRef={(e) => {
                                formPassword.register(e);
                                passwordRef.current = e;
                              }}
                              />
                              <FormHelperText>
                                {formPassword.errors.password?.message}
                              </FormHelperText>
                            </FormControl>
                            <div className={classes.wrapper}>
                              <Button
                                disabled={loading}
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                size="large"
                              >
                                Entrar
                                </Button>
                              {loading && (
                                <CircularProgress
                                  size={24}
                                  className={classes.buttonProgress}
                                />
                              )}
                            </div>
                          </form>
                        </CardBody>
                      }
                      {segundoPasso == SegundoPasso.cadastroSenha &&
                        <CardBody>
                          <div className={classes.rootAvatar}>
                            <Avatar alt={userTemp?.nome} src={userTemp?.avatarUrl} className={classes.avatarLarge} />
                            <Typography variant="body2" component="p">Olá, {userTemp?.nome}</Typography>
                          </div>
                          <Grid container spacing={3}>
                            <Grid item xs={12}>
                              <FormControl fullWidth error={!!formPasswordSignUp.errors.email?.message}>
                                <InputLabel htmlFor="email">E-mail</InputLabel>
                                <Input name="email" id="email" type="email" autoComplete="off"
                                  inputRef={(e) => {
                                    formPasswordSignUp.register(e);
                                    emailSignupRef.current = e;
                                  }}
                                  endAdornment={
                                    <InputAdornment position="end">
                                      <Email />
                                    </InputAdornment>
                                  } />
                                <FormHelperText>{formPasswordSignUp.errors.email?.message}</FormHelperText>
                              </FormControl>
                            </Grid>
                          </Grid>
                          <form onSubmit={formPasswordSignUp.handleSubmit(onSubmitSignupPassword)}>
                            <CardTextHeader>
                              <h2>Crie uma senha</h2>
                              <span className={classes.SpanCard}>
                                <HelpOutline fontSize="small" className={classes.IconInfo} /> Mínimo 6 dígitos
                                </span>
                            </CardTextHeader>
                            <Grid container spacing={3}>


                              <Grid item xs={12} >
                                <FormControl fullWidth error={!!formPasswordSignUp.errors.password?.message}>
                                  <InputLabel htmlFor="password">Nova senha</InputLabel>
                                  <Input name="password" id="password" type="password"
                                    inputRef={(e) => {
                                      formPasswordSignUp.register(e); passwordRef.current = e
                                    }} autoComplete="off" />
                                  <FormHelperText>{formPasswordSignUp.errors.password?.message}</FormHelperText>
                                </FormControl>
                              </Grid>

                              <Grid item xs={12}>

                                <FormControl fullWidth error={!!formPasswordSignUp.errors.password_confirmation?.message}>
                                  <InputLabel htmlFor="password_confirmation">Confirmar senha</InputLabel>
                                  <Input name="password_confirmation" id="password_confirmation" type="password" inputRef={formPasswordSignUp.register} autoComplete="off" />
                                  <FormHelperText>{formPasswordSignUp.errors.password_confirmation?.message}</FormHelperText>
                                </FormControl>
                              </Grid>
                            </Grid>
                            <div className={classes.wrapper}>
                              <Button disabled={loading} type="submit" fullWidth variant="contained" color="primary" size="large">
                                Salvar
                            </Button>
                              {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </div>
                          </form>
                        </CardBody>
                      }
                    </Slide>
                    {/* <Slide innerClassName={classes.RingOff} index={2} >
                      <CardBody>
                          <CardTextHeader>
                            <h2>Digite seu CPF</h2>
                          </CardTextHeader>

                        </CardBody>
                </Slide> */}
                  </Slider>
                </CarouselProvider>
                <CardFooter>
                  {tab > 0 && (
                    <FooterCard>
                      <Button onClick={handleClickReturn}>
                        <ArrowLeft />
                        Voltar
                      </Button>
                    </FooterCard>
                  )}
                </CardFooter>
              </Card>

              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <h3 className={classes.titleSignup}>É novo(a) aqui?</h3>
                <Button
                  onClick={handleSignUpClick}
                  type="submit"
                  variant="contained"
                  color="primary"
                  size="large"
                  startIcon={<PersonAdd />}
                >
                  Matricule-se
                </Button>
              </Grid>
            </AnimationContainer>
          </GridItem>
        </GridContainer>
      </Content>

      <Dialog
        maxWidth="xs"
        fullWidth
        onClose={handleCloseModal}
        aria-describedby="form-dialog-title"
        open={openModal}
      >
        <ContentModal>
          <CardBody>
            <CardTextHeader>
              <h2>Digite seu CPF</h2>
            </CardTextHeader>
            <form
              onSubmit={formCpfSignUp.handleSubmit(handleSubmitFindCPFSignUp)}
            >
              <FormControl
                fullWidth
                error={!!formCpfSignUp.errors.cpf?.message}
              >
                <InputLabel htmlFor="cpf">CPF</InputLabel>
                <InputMask mask="999.999.999-99" disabled={false} maskChar="">
                  {() => (
                    <Input
                      name="cpf"
                      id="cpf"
                      type="tel"
                      inputRef={(e) => {
                        formCpfSignUp.register(e);
                        cpfSignupRef.current = e;
                      }}
                    />
                  )}
                </InputMask>
                <FormHelperText>
                  {formCpfSignUp.errors.cpf?.message}
                </FormHelperText>
              </FormControl>

              <div className={classes.wrapper}>
                <Button
                  disabled={loading}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  size="large"
                  endIcon={<ArrowForwardIos />}
                >
                  Continuar
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </form>
          </CardBody>
        </ContentModal>
      </Dialog>
    </>
  );
};
export default SignIn;
