export interface IPaymentSaleDTO{
  idPacote: string;
  formaPagamento: FormPaymentEnum;
  parcelas: number;
  cartao?: CreditCardDTO;
  idTenant: string;
  recaptcha: string | null,
  codeVoucher?: string | null,
  idConvenio?: number| null,
  idVoucher?: number| null,
  idMaquininha?: number| null,
}
export interface CreditCardDTO{
  numero: string;
  nomeCompleto: string;
  mes: number;
  ano: number;
  cvc: string;
  bandeira: string;
}
export enum FormPaymentEnum {
  CartaoCredito = 2,
  CartaoDebito = 3,
  Boleto = 7,
  Pix = 11
}
