import React, {  useEffect, useMemo, useState } from 'react'
import {  Typography, TypographyProps } from '@material-ui/core';
import {format} from 'date-fns';



const Clock: React.FC<TypographyProps>  = ({...props}) => {

  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);
  }, []);


        return (
        <Typography {...props}>{format(currentTime,"HH:mm:ss")}</Typography>
        )

}
export default Clock;
